import { useState } from 'react';

import { useEvent } from '@ecp/utils/react';

import { getHeatingSource, getHeatingSourceRefs } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { useHeatingSources } from '../../../../state';

export const useValidateHeatingSource = (): {
  validateHeatingSource: () => { isValid: boolean };
  hasHeatingSourceError: boolean;
  handleHeatingSourceErrorStatusChange: (hasHeatingSourceError: boolean) => void;
} => {
  const heatingSourceRefs = useSelector(getHeatingSourceRefs);
  const heatingSourcesValues = useHeatingSources(heatingSourceRefs);
  const hasHeatingSource = useSelector(getHeatingSource);

  const [hasHeatingSourceError, setHasHeatingSourceError] = useState(false);

  const checkNoHeatingSourceError = useEvent(() => {
    const invalidHeatSourceValues = heatingSourcesValues.filter(
      (heatingSourceValue) => !heatingSourceValue.fuelType && !heatingSourceValue.material,
    );
    const hasHeatSourceError =
      hasHeatingSource && (!heatingSourcesValues?.length || invalidHeatSourceValues?.length);
    setHasHeatingSourceError(!!hasHeatSourceError);

    if (!hasHeatSourceError) {
      return true;
    }

    return false;
  });

  const handleHeatingSourceErrorStatusChange = (errorExist: boolean): void => {
    setHasHeatingSourceError(errorExist);
  };

  const validateHeatingSource = (): { isValid: boolean } => {
    const isNoError = checkNoHeatingSourceError();
    if (!isNoError) {
      setHasHeatingSourceError(true);
    }

    return {
      isValid: isNoError,
    };
  };

  return {
    validateHeatingSource,
    hasHeatingSourceError,
    handleHeatingSourceErrorStatusChange,
  };
};
