import { agentAuth } from '@ecp/utils/auth';
import { FeatureFlags, flagValues, flagVariables } from '@ecp/utils/flags';
import { validateAndCombineAddress } from '@ecp/utils/geo';
import { location } from '@ecp/utils/routing';
import { sessionStorage } from '@ecp/utils/storage';

import { env } from '@ecp/env';
import {
  fetchAcknowledgementResponse,
  fetchPaymentOptions,
  fetchPostBindQuestions,
  setHomeInspectionStatus,
  setPurchaseRequestProduct,
} from '@ecp/features/sales/checkout';
import {
  ADDRESS_PREFILL_SUCCESSFUL,
  LEXIS_NEXIS_CALL,
  LEXIS_NEXIS_TRIGGER,
  LINE_OF_BUSINESS,
  PARTNER,
  PARTNER_ACCOUNT,
  PARTNER_EXPERIENCE_ID,
  PARTNER_SEGMENT,
  POLICY_EFFECTIVE_DATE_PARAM,
  PRIMARY_INSURED_ADDRESS_REF,
  PRIMARY_INSURED_MAILING_ADDRESS_REF,
  PRIMARY_INSURED_PERSON_REF,
  RENTERS_POLICY_START_DATE,
  STATE_CODE_PREFIX,
  USER_SELECTION,
} from '@ecp/features/sales/shared/constants';
import type { Session } from '@ecp/features/sales/shared/misc';
import { getSession, setSession } from '@ecp/features/sales/shared/misc';
import {
  getAllPageFlowsFromConfig,
  getNavigateToPrimaryFlowStepOnResume,
  getProductOrderFromConfig,
  PagePath,
} from '@ecp/features/sales/shared/routing';
import {
  createInquiry,
  createRef,
  fetchApi,
  fetchInquiry,
  fetchOffers,
  fetchRatingCriteria,
  getDalSessionId,
  getIsProductOfferBindable,
  getIsProductOfferPurchased,
  getOfferProductsSelected,
  getPrimaryInsuredPersonRef,
  getUserSelection,
  setDalSessionId,
  setInquiryId,
  setNavAll,
  setZipLookupBypassed,
  updateAnswers,
  updateGlobalSeed,
  updateInitializing,
  updateStartedInitializing,
} from '@ecp/features/sales/shared/store';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';
import type { Answers } from '@ecp/features/sales/shared/types';
import {
  getLineOfBusinessFromQuery,
  isLineOfBusinessSupported,
} from '@ecp/features/shared/product';
import type { ExperienceId } from '@ecp/partners';

import { getAnalyticsAnswersFromQueryForInquiry } from './getAnalyticsAnswersFromQueryForInquiry';
import { composeFullStreetAddress, initializeUsingQuery } from './initializeUsingQuery';
import metadata from './metadata';
import { adjustPolicyEffectiveStartDate } from './metadata/util/policyEffectiveDateAdjustor';
import { createPolicyEffectiveStartDateAnswers } from './utils/policyEffectiveStartDate';

export type InitialInquiryDetails = {
  pniPerson: {
    firstName: string;
    lastName: string;
    email: string;
  };
  primaryAddress: {
    line1: string;
    line2: string;
    city: string; // two letter state abbreviation eg. "WI"
    state: string;
    zipcode: string;
  };
  policyEffectiveDate?: string;
  additionalAnswers: Answers;
};

export const createAnswersFromFullAddress: (
  primaryInsuredAddressRef: string,
) => ThunkAction<Promise<Answers>> = (primaryInsuredAddressRef) => async (dispatch) => {
  const { city, line1, line2, zip: zipcode, product } = location.search;

  // Zillow scenario only at this moment where we expect full address in the URL
  if (!line1 || !city || !zipcode) return {};

  const lineOfBusiness = getLineOfBusinessFromQuery(product);
  const answersToBeUpdated: Answers = {};

  if (isLineOfBusinessSupported(lineOfBusiness)) {
    answersToBeUpdated[LINE_OF_BUSINESS] = lineOfBusiness;
  }

  if (line1) answersToBeUpdated[`${primaryInsuredAddressRef}.line1`] = line1;
  if (line2) answersToBeUpdated[`${primaryInsuredAddressRef}.line2`] = line2;
  if (city) answersToBeUpdated[`${primaryInsuredAddressRef}.city`] = city;
  if (zipcode) answersToBeUpdated[`${primaryInsuredAddressRef}.zipcode`] = zipcode;

  const street = composeFullStreetAddress(line1, line2);
  const validatedPersonAddress = await validateAndCombineAddress(
    { city: city, street, zipcode },
    primaryInsuredAddressRef,
  );
  if (validatedPersonAddress) {
    answersToBeUpdated[
      `${primaryInsuredAddressRef}.state`
    ] = `${STATE_CODE_PREFIX}${validatedPersonAddress.state}`;
    dispatch(setZipLookupBypassed(true));
  }
  answersToBeUpdated[ADDRESS_PREFILL_SUCCESSFUL] = Boolean(validatedPersonAddress);

  return answersToBeUpdated;
};

export const initializeUsingSession =
  ({
    session,
    restart,
    isBridge,
    initialInquiryDetails,
  }: {
    session: Session;
    restart: boolean;
    isBridge?: boolean;
    initialInquiryDetails?: InitialInquiryDetails;
  }): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const work = [];

    const { dalSessionId, inquiryId, offerSetId, nav, homeInspectionStatus } = session;

    // This will ensure no additional call to SAPI if an
    // agent token is not available
    if (env.static.isAgent && !agentAuth.isAuth) {
      return;
    }

    if (!dalSessionId && inquiryId) {
      work.push(
        (async () => {
          await dispatch(createInquiry({ answers: { quoteId: inquiryId } }));
          // EDSP-11130 - Resume flow for experiences coming with url and quoteid
          await dispatch(getNavigateToPrimaryFlowStepOnResume());
        })(),
      );
    } else if (restart || !dalSessionId) {
      if (env.static.isAgent) return;

      const analyticsAnswers = getAnalyticsAnswersFromQueryForInquiry();

      // POST to make a new inquiry
      // This will fail for agent and agents will be forced to enter credentials
      const personRef = dispatch(createRef('person'));
      const policyRiskAddressRef = dispatch(createRef('address'));

      const partnerExperienceId = sessionStorage.getItem(PARTNER_EXPERIENCE_ID) as ExperienceId;

      // Zillow specific- since we are not expecting line1, city, zip in query params for other partners
      const addressWithPrefillAnalyticsAndLOB = await dispatch(
        createAnswersFromFullAddress(policyRiskAddressRef),
      );

      work.push(
        dispatch(
          createInquiry({
            answers: {
              ...analyticsAnswers,
              ...createPolicyEffectiveStartDateAnswers(
                location.search.policyEffectiveDate,
                location.search.product,
                !!metadata.shouldGetPolicyEffectiveDateQueryParam,
                metadata.policyEffectiveStartDateAdjustors,
              ),
              ...(metadata.defaultLineOfBusiness && {
                [LINE_OF_BUSINESS]: metadata.defaultLineOfBusiness,
              }),
              ...(env.static.defaultUserSelection && {
                [USER_SELECTION]: env.static.defaultUserSelection,
              }),
              [PRIMARY_INSURED_PERSON_REF]: personRef,
              [PRIMARY_INSURED_ADDRESS_REF]: policyRiskAddressRef,
              [PRIMARY_INSURED_MAILING_ADDRESS_REF]: policyRiskAddressRef,
              [PARTNER_EXPERIENCE_ID]: partnerExperienceId || env.static.expId,
              ...(initialInquiryDetails &&
                convertInitialInquiryDetailsToAnswers({
                  initialInquiryDetails,
                  personRef,
                  primaryAddressRef: policyRiskAddressRef,
                })),
              ...addressWithPrefillAnalyticsAndLOB,
              // To meet sapi's rule for customized default start date
              // TODO: 'analytics.variants': stuff
            },
          }),
        ),
      );
    } else {
      // Setting this here so we don't need to pass it across all fetch calls
      // TODO Remove props drilling dalSessionId to all fetch calls
      dispatch(setDalSessionId(dalSessionId));

      if (nav) {
        dispatch(setNavAll(nav));
      }
      if (offerSetId) {
        // Updated fetchOffers to also update latest questions and answers
        // so no need to make a separate call to fetchInquiry
        await dispatch(fetchOffers({ dalSessionId }));

        const state = getState();

        const userSelection = getUserSelection(state);
        const offerProductsSelected = getOfferProductsSelected(state);

        if (userSelection && offerProductsSelected.length > 0) {
          const bindableProducts = offerProductsSelected.filter((product) =>
            getIsProductOfferBindable(state, product),
          );
          const purchasedProducts = offerProductsSelected.filter((product) =>
            getIsProductOfferPurchased(state, product),
          );

          // Fetch payment options and acknowledgements only when product.userSelection exists and offer bindable
          if (bindableProducts.length === offerProductsSelected.length) {
            work.push(dispatch(fetchPaymentOptions({ dalSessionId, products: bindableProducts })));
            work.push(
              dispatch(
                fetchAcknowledgementResponse({
                  dalSessionId,
                  products: bindableProducts,
                }),
              ),
            );
            bindableProducts.forEach((product) => {
              work.push(dispatch(fetchRatingCriteria({ dalSessionId, product })));
            });
            dispatch(setPurchaseRequestProduct(bindableProducts));
          }
          // Fetch post bind questions and post reminders if a product has been purchased
          if (!metadata.skipFetchPostBindQuestions && purchasedProducts.length > 0) {
            work.push(dispatch(fetchPostBindQuestions({ dalSessionId })));
            work.push(
              dispatch(
                fetchAcknowledgementResponse({
                  dalSessionId,
                  products: purchasedProducts,
                  category: 'reminders',
                }),
              ),
            );
          }
          work.push(dispatch(setHomeInspectionStatus(homeInspectionStatus || '')));
        }
      } else {
        // Answers needed for Optimizely
        work.push(
          (async () => {
            await dispatch(fetchInquiry({ dalSessionId }));
            // following code is specifically going to be called for GM mybrand(OSI) prefill flow
            // it will be invoked when we have dalSessionId in the URL at the start of the application
            // static.bridgeType answer is going to be used on person pages.
            if (isBridge) {
              await dispatch(
                updateAnswers({
                  answers: {
                    'static.bridgeType': 'customerPrefill',
                    [LEXIS_NEXIS_TRIGGER]: true,
                    [LEXIS_NEXIS_CALL]: true,
                    ...(env.static.defaultUserSelection && {
                      [USER_SELECTION]: env.static.defaultUserSelection,
                    }),
                  },
                }),
              );
            }
          })(),
        );
      }
    }
    await Promise.all(work);
  };

const convertInitialInquiryDetailsToAnswers = ({
  initialInquiryDetails,
  personRef,
  primaryAddressRef,
}: {
  initialInquiryDetails: InitialInquiryDetails;
  personRef: string;
  primaryAddressRef: string;
}): Answers => {
  return {
    [`${personRef}.firstName`]: initialInquiryDetails?.pniPerson.firstName,
    [`${personRef}.lastName`]: initialInquiryDetails?.pniPerson.lastName,
    [`${personRef}.email`]: initialInquiryDetails?.pniPerson.email,
    [`${primaryAddressRef}.line1`]: initialInquiryDetails?.primaryAddress.line1,
    [`${primaryAddressRef}.line2`]: initialInquiryDetails?.primaryAddress.line2,
    [`${primaryAddressRef}.city`]: initialInquiryDetails?.primaryAddress.city,
    [`${primaryAddressRef}.state`]: `${STATE_CODE_PREFIX}${initialInquiryDetails?.primaryAddress.state}`,
    [`${primaryAddressRef}.zipcode`]: initialInquiryDetails?.primaryAddress.zipcode,
    [POLICY_EFFECTIVE_DATE_PARAM]: initialInquiryDetails?.policyEffectiveDate,
    ...(initialInquiryDetails?.policyEffectiveDate && {
      [RENTERS_POLICY_START_DATE]: adjustPolicyEffectiveStartDate(
        initialInquiryDetails.policyEffectiveDate,
      ),
    }),
    ...initialInquiryDetails.additionalAnswers,
  };
};

export const initializeSession: ThunkAction<Promise<void>> = async (dispatch) => {
  // Any pageNeedsNoSession logic should be handled by the calling
  // function. This function should purely be used for initializing session
  dispatch(updateGlobalSeed(+new Date()));

  const searchParams = location.search;
  const isRelateFlow = location.pathname.includes(PagePath.LANDING) && searchParams.quoteId;

  if (isRelateFlow) {
    sessionStorage.setItem('relateFlow', true, true);
  }

  // msid is given usually by the router, we reset the application when we see it,
  // this covers us if the user goes back to before the router and arrives at our
  // site again (we would want to ensure it's a new session).
  // restart the application if msId is present
  if (searchParams.msId) {
    const newSession: Session = {
      dalSessionId: '',
      inquiryId: '',
      offerSetId: '',
      expId: env.static.expId,
      ...(env.static.isAgent &&
        !agentAuth.isAuth && {
          [PARTNER]: null,
          [PARTNER_ACCOUNT]: null,
          [PARTNER_EXPERIENCE_ID]: null,
          [PARTNER_SEGMENT]: null,
        }),
    };

    setSession(newSession);

    await dispatch(
      initializeUsingSession({
        session: newSession,
        restart: true,
      }),
    );
  } else if (searchParams.inquiryId) {
    // setting this here as we do not have access to inquiryId yet for this flow.
    dispatch(setInquiryId(searchParams.inquiryId));
    // as we are getting the inquiryId directly from GM we don't have to post inquiries to SAPI.
    // so we are creating a new session with the received inquiryId instead of creating an inquiry.
    const newSession: Session = {
      dalSessionId: '',
      inquiryId: searchParams.inquiryId,
      offerSetId: '',
      expId: env.static.expId,
    };
    setSession(newSession);

    await dispatch(
      initializeUsingSession({
        session: newSession,
        restart: false,
        isBridge: true,
      }),
    );
  } else if (searchParams.quoteId) {
    // setting this here as we do not have access to inquiryId yet for this flow.
    dispatch(setInquiryId(searchParams.quoteId));
    const newSession: Session = {
      dalSessionId: '', // TODO: Add dalSessionId to `newSession`
      inquiryId: searchParams.quoteId,
      offerSetId: '',
      expId: env.static.expId,
    };
    setSession(newSession);

    await dispatch(
      initializeUsingSession({
        session: newSession,
        restart: false,
        isBridge: true, // ???
      }),
    );
  } else {
    const savedSession = getSession({ expId: env.static.expId });
    await dispatch(
      initializeUsingSession({
        session: savedSession,
        restart: false,
      }),
    );
  }
};

export const initializeEventListeners: ThunkAction<Promise<void>> = async (dispatch, getState) => {
  const { communication } = location.search;
  const pniRef = getPrimaryInsuredPersonRef(getState());
  // Feature flag for zillow Hackathon to allow ECP in an iframe (https://www.zillow.com/ and https://www.qa.zillow.net/)
  const handleMessage = async (event: MessageEvent): Promise<void> => {
    // Check if the origin matches the allowed pattern
    const acceptedOrigins = flagVariables.IFRAME_EXPERIENCE.ACCEPTED_ORIGINS;
    if (acceptedOrigins) {
      const allowedOriginRegex = new RegExp(acceptedOrigins);
      if (!allowedOriginRegex.test(event.origin)) return;
    }
    // Check the queryparam communication to be iframe
    if (communication !== 'iframe') return;
    const { firstName, lastName, email } = event.data; // Dispatch answers with the received data
    await dispatch(
      updateAnswers({
        answers: {
          [`${pniRef}.firstName`]: firstName,
          [`${pniRef}.lastName`]: lastName,
          [`${pniRef}.email`]: email,
        },
      }),
    );
  };
  // Add the event listener for message events
  window.addEventListener('message', handleMessage);
};

export const bootstrapSession =
  ({ isMonoline }: { isMonoline?: boolean }): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const allowInIframe = flagValues[FeatureFlags.IFRAME_EXPERIENCE];

    await dispatch(
      fetchApi({
        fn: async () => {
          dispatch(updateStartedInitializing(true));
          await dispatch(initializeSession);
          // Zillow Hackweek event listener when ECP is used inside an iframe
          if (allowInIframe) await dispatch(initializeEventListeners);

          await dispatch(initializeUsingQuery);

          if (!isMonoline) await dispatch(getProductOrderFromConfig());
          await dispatch(getAllPageFlowsFromConfig({}));

          const dalSessionId = getDalSessionId(getState());

          if (dalSessionId || env.static.isAgent) {
            dispatch(updateInitializing(false));
            dispatch(updateStartedInitializing(false));
          }
        },
        idPrefix: 'bootstrap-session',
      }),
    );
  };
