// property
export const OCCUPANCY_TYPE = 'property.occupancyType';
export const SINGLE_OR_MULTI_FAMILY = 'property.singleOrMultiFamily';
export const CONSTRUCTION_TYPE = 'property.constructionType';
export const STYLE_OF_HOME = 'property.styleOfHome';
export const FULL_BATHS = 'property.fullBaths';
export const HALF_BATHS = 'property.halfBaths';
export const FOUNDATION_PERCENTAGE_FINISHED = 'property.foundation.percentageFinished';
export const FOUNDATION_TYPE = 'foundation.<id>.foundationType';
export const STATIC_FOUNDATION_TYPE = 'static.foundationType';
export const LIVING_AREA = 'property.livingArea';
export const KITCHEN_COUNTERTOP_MATERIAL = 'property.kitchenCountertopMaterial';
export const NUMBER_OF_STORIES = 'property.numberOfStories';
export const ROOF_SHAPE = 'property.roof.shape';
export const ROOF_MATERIAL = 'property.roof.material';
export const ROOF_ANY_PORTION_FLAT = 'property.roof.anyPortionFlat';
export const YEAR_BUILT = 'property.yearBuilt';
export const EXTERIOR_WALL_REF = 'property.exteriorWall.ref';
export const FOUNDATION_REF = 'property.foundation.ref';
export const INTERIOR_WALL_REF = 'property.interiorWall.ref';
export const FLOOR_REF = 'property.floor.ref';
export const GARAGE_TYPE = 'property.garage.type';
export const GARAGE_SIZE = 'property.garage.size';
export const NUMBER_OF_MORTGAGES = 'property.noOfMortgages';
export const YEAR_OCCUPIED = 'property.yearOccupied';
export const NUMBER_OF_CATHEDRAL_OR_VAULTED_CEILINGS = 'property.ceiling.cathedralOrVaulted';
export const NUMBER_OF_ROOMS_WITH_CROWN_MOULDING = 'property.ceiling.roomsWithCrownMolding';
export const NUMBER_OF_SEPARATE_LIVING_UNITS = 'property.singleOrMultiFamily';
export const BUSINESS_ON_PREMISES = 'property.businessOnPremises';
export const HOME_BUILT_ON_SLOPE = 'property.homeBuiltOnSlope';
export const AIR_CONDITIONING = 'property.airConditioning';

// home interior
export const HEATING_SOURCE_REF = 'property.heatingSource.ref';
export const SECONDARY_HEATING_SOURCE_REF = 'property.secondaryHeatingSource.ref';
export const STATIC_HAS_HEATING_SOURCE = 'static.hasHeatingSource';
export const HEATING_SOURCE_TYPE = 'heatingSource.<id>.type';
export const HEATING_SOURCE_MATERIAL = 'heatingSource.<id>.material';
export const HEATING_SOURCE_FUEL_TYPE = 'heatingSource.<id>.fuelType';
export const INTERIOR_WALL_MATERIAL_SUFFIX = 'material';
export const INTERIOR_WALL_MATERIAL_PERCENTAGE_SUFFIX = 'percentage';
export const INTERIOR_WALL_REF_NAME = 'interiorWall';
export const FLOOR_TYPE_SUFFIX = 'type';
export const FLOOR_TYPE_PERCENTAGE_SUFFIX = 'percentage';
export const FLOOR_TYPE_REF_NAME = 'floor';
export const INTERIOR_WALL_MATERIAL = 'interiorWall.<id>.material';
export const STATIC_INTERIOR_WALL = 'static.interiorWall';
export const FLOOR_TYPE = 'floor.<id>.type';
export const STATIC_FLOOR = 'static.floor';

// home basic
export const BUSINESS_ON_PREMISES_REF = 'property.businessOnPremises.ref';
export const FOUNDATION_PERCENTAGE_SUFFIX = 'percentage';
export const FOUNDATION_TYPE_SUFFIX = 'foundationType';

// home exterior
export const CONSTRUCTION_MATERIAL_SUFFIX = 'constructionMaterial';
export const CONSTRUCTION_MATERIAL_PERCENTAGE_SUFFIX = 'constructionMaterialPercentage';
export const EXTERIOR_WALL_CONSTRUCTION_MATERIAL = 'exteriorWall.<id>.constructionMaterial';
export const STATIC_EXTERIOR_WALL = 'static.exteriorWall';
