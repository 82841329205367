import { useEffect } from 'react';

import { sessionStorage } from '@ecp/utils/storage';

import { env } from '@ecp/env';
import { PARTNER_EXPERIENCE_ID } from '@ecp/features/sales/shared/constants';
import {
  getLineOfBusiness,
  getPartnerExperienceId,
  getPrimaryInsuredStateCode,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { updateUserAttributesAndDecisionAnalytics } from '@ecp/features/sales/shared/utils/analytics';
import type { ExperienceId } from '@ecp/partners';

/**
 * Keeps track of various variables in the app which it associates with the current user session.
 */
export const useFlags = (): void => {
  const isAgentContainerId = (expId: string): string =>
    ['1600', '7089', '2795'].includes(expId) ? '' : expId;
  const expId = (sessionStorage.getItem(PARTNER_EXPERIENCE_ID) || env.static.expId) as ExperienceId;
  const partnerExpId = useSelector(getPartnerExperienceId) ?? isAgentContainerId(expId);
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  useEffect(() => {
    if (lineOfBusiness || partnerExpId || stateCode) {
      updateUserAttributesAndDecisionAnalytics({
        product: lineOfBusiness || null,
        expId: partnerExpId,
        stateCode,
      });
    }
  }, [lineOfBusiness, partnerExpId, stateCode]);
};
