import { useCallback } from 'react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';

import { ThirdPartyInterestForm } from '../../forms/ThirdPartyInterestForm/ThirdPartyInterestForm';

export const ThirdPartyInterestPage: React.FC = () => {
  const navigateToNextPage = useNavigateToNextPage();

  const handleNext = useCallback(async () => {
    await navigateToNextPage();

    return;
  }, [navigateToNextPage]);

  return (
    <div>
      <Page
        title='Add an interested party to your policy?'
        subTitle='An interested party is most often a business, like a mortgage company or auto lender, that will be notified about coverage and changes made to your policy. They will not be named on your policy and will be unable to modify it or benefit from a claim.'
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.THIRD_PARTY_INTEREST} />,
        }}
      >
        <ThirdPartyInterestForm onNext={handleNext} />
      </Page>
    </div>
  );
};
