import { useRef } from 'react';

import { Divider, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';

import { GridItem } from '@ecp/components';
import {
  useGetConditionValues,
  useGetFields,
  useGetInitValues,
  waitForRerender,
} from '@ecp/features/sales/form';
import { Button, Form } from '@ecp/features/sales/shared/components';
import { useForm } from '@ecp/features/sales/shared/store';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { goToFirstError } from '@ecp/features/sales/shared/utils/web';

import { useValidateHeatingSource } from '../../components/Question/HomeInterior/HeatingSourceQuestion/useValidateHeatingSource';
import { HomeInteriorFormQuestions } from '../../formBody';
import { useStyles } from './HomeInteriorForm.styles';

export interface HomeInteriorProps {
  onNext: () => Promise<void>;
}

export const HomeInteriorForm: React.FC<HomeInteriorProps> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const getFields = useGetFields();
  const getInitValues = useGetInitValues();
  const conditions = useGetConditionValues();

  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: conditions(),
  });

  const { validateHeatingSource, hasHeatingSourceError, handleHeatingSourceErrorStatusChange } =
    useValidateHeatingSource();

  const handleSubmit = useEvent(async () => {
    const hasHeatingSourceError = !validateHeatingSource().isValid;
    const hasFormError = !validateForm({ goToError: true }).isValid;
    const hasError = hasHeatingSourceError || hasFormError;
    if (hasError) {
      await waitForRerender();
      goToFirstError();

      return;
    }

    await patchFormValues();
    trackSapiAnalyticsEvent({
      element: 'choice.homeInteriorPage.saveAndContinueButton',
      event: 'click',
      eventDetail: 'true',
    });
    await onNext();
  });

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <HomeInteriorFormQuestions
            hasHeatingSourceError={hasHeatingSourceError}
            onHeatingSourceErrorStatusChange={handleHeatingSourceErrorStatusChange}
          />
          <GridItem topSpacing='lg' xs={12}>
            <Divider />
          </GridItem>
          <GridItem topSpacing='lg' xs={12}>
            <h3>Thanks! This finishes the details on the inside of the home.</h3>
          </GridItem>
          <Grid container item xs={12}>
            <Button
              id='submit'
              variant='primary'
              onClick={handleSubmit}
              data-testid='showMyDiscounts'
              isProcessing={isPatchFormInProgress}
              classes={{ root: classes.nextButton }}
              className={classes.next}
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='home_interior_continue'
            >
              Show my discounts
            </Button>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};
