// TODO Supposed to provide utils for react hook form on the sales side
// TODO Forms to be rewritten without Redux usage.

import { castAnswerType } from '@ecp/utils/common';

import {
  evaluateValidationErrors,
  getAllValues,
  getFieldValue,
  getQuestion,
  getValidationErrors,
} from '@ecp/features/sales/shared/store';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';
import type { AnswerValue } from '@ecp/features/sales/shared/types';

interface ErrorData {
  [key: string]: { message: string };
}

interface ValidateFieldByKeyProps {
  key: string;
  value: AnswerValue;
}

/**
 * This fn validateFieldByKey is used to get validation errors for a field by passing just key, value
 * Return format of this function is in the format needed by react hook form
 **/
export const validateFieldByKey =
  ({ key, value }: ValidateFieldByKeyProps): ThunkAction<ErrorData[]> =>
  (dispatch, getState) => {
    const result = [];
    const state = getState();
    const question = getQuestion(key)(state);
    const dependentQuestionKeys = question.dependentQuestionKeys;
    const errors = evaluateValidationErrors({
      question,
      value,
      allValues: getAllValues(state),
      state,
      dispatch,
      questionKey: key,
    });

    if (errors.length) {
      result.push({ [key]: { message: errors[0] } });
    }
    if (dependentQuestionKeys) {
      dependentQuestionKeys.forEach((questionKey) => {
        const dependentQuestion = getQuestion(questionKey)(getState());
        const dependentValue = castAnswerType(
          getFieldValue(getState(), questionKey),
          dependentQuestion.answerType,
        );
        if (dependentValue) {
          const dependentQuestionErrors = getValidationErrors({
            question: dependentQuestion,
            valueProp: dependentValue,
            allValues: getAllValues(getState()),
            questionKey: questionKey,
          });
          if (dependentQuestionErrors.length) {
            result.push({ [questionKey]: { message: dependentQuestionErrors[0] } });
          }
        }
      });
    }

    return result;
  };

export const waitForRerender = async (): Promise<void> => {
  await new Promise((resolve) => setTimeout(resolve, 0));
};
