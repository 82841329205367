import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { noop } from '@ecp/utils/common';

import { Button, GridItem } from '@ecp/components';
import type { ButtonProps } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { getFullPersonInfo } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconMaterialDesignActionOpenInNew } from '@ecp/themes/base';

import { useStyles } from './InPersonClick2SignPerson.styles';

export interface Props extends QuestionProps {
  personRef: string;
}

export const InPersonClick2SignPerson: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { personRef } = props;
  const { firstName, lastName } = useSelector((state) => getFullPersonInfo(state, personRef));

  const handleInPersonScript = useCallback<NonNullable<ButtonProps['onClick']>>(() => {
    return noop();
    /* TODO - EDSP-11397- to update button functionality*/
  }, []);

  return (
    <Grid container item xs={12} className={classes.container} spacing={1}>
      <GridItem topSpacing='md' className={classes.items} xs={12}>
        <p className={classes.namedInsured}>{firstName + ' ' + lastName}</p>
      </GridItem>
      <GridItem topSpacing='md' className={classes.button} xl={6} md={3} xs={12}>
        <Button
          onClick={handleInPersonScript}
          variant='outlinePrimary'
          color='primary'
          icon={<IconMaterialDesignActionOpenInNew />}
          iconLast
        >
          SIGN DOCUMENTS IN DOCUSIGN
        </Button>
      </GridItem>
    </Grid>
  );
};
