import { emptyArray, includes, upperFirst } from '@ecp/utils/common';

import type { CarrierName } from '../carrier/types';
import { getCarrierDisplayName } from '../carrier/util';
import { LineOfBusiness } from './constants';
import type {
  AutoProduct,
  HomeProduct,
  LineOfBusinessAuto,
  LineOfBusinessBundled,
  LineOfBusinessProperty,
  LineOfBusinessUnbundled,
  Product,
  ProductDisplayName,
  ProductExtendedDisplayName,
  ProductName,
  PropertyProduct,
  PropertyProductName,
  ReducedProductName,
  RentersProduct,
} from './types';

const supportedProducts = [
  'connect.auto',
  'connect-boost.auto',
  'amfam.auto',
  'amfam-adv.auto',
  'amfam-adv.home',
  'amfam-adv.renters',
  'homesite.home',
  'homesite.renters',
] as const;

export const isProductAvailable = (product: unknown): product is Product =>
  includes(supportedProducts, product);

export const isProductAuto = (product: unknown): product is AutoProduct =>
  typeof product === 'string' && product.endsWith('auto');

export const isProductHome = (product: unknown): product is HomeProduct =>
  typeof product === 'string' && product.endsWith('home');

export const isProductRenters = (product: unknown): product is RentersProduct =>
  typeof product === 'string' && product.endsWith('renters');

export const isProductProperty = (product: unknown): product is PropertyProduct =>
  isProductHome(product) || isProductRenters(product);

export const hasAutoProduct = (products: Product[]): boolean => products.some(isProductAuto);

export const hasHomeProduct = (products: Product[]): boolean => products.some(isProductHome);

export const hasRentersProduct = (products: Product[]): boolean => products.some(isProductRenters);

export const hasPropertyProduct = (products: Product[]): boolean =>
  products.some(isProductProperty);

export const getCarrierNameFromProduct = (product: Product): CarrierName =>
  product.substring(0, product.indexOf('.')) as CarrierName;

export const getProductNameFromProduct = (product: Product): ProductName =>
  product.substring(product.indexOf('.') + 1, product.length) as ProductName;

export const getReducedProductNameFromProduct = (product: Product): ReducedProductName =>
  isProductProperty(product)
    ? 'property'
    : (getProductNameFromProduct(product) as Exclude<ProductName, PropertyProductName>);

export const getReducedProductNameFromProductName = (
  productName: ProductName,
): ReducedProductName =>
  isProductProperty(productName)
    ? 'property'
    : (productName as Exclude<ProductName, PropertyProductName>);

export const getProductDisplayName = (productName: ProductName): ProductDisplayName =>
  upperFirst(productName) as ProductDisplayName;

export const getProductDisplayNameFromProduct = (product: Product): ProductDisplayName => {
  const productName = getProductNameFromProduct(product);

  return getProductDisplayName(productName);
};

export const getProductExtendedDisplayNameFromProduct = (
  product: Product,
): ProductExtendedDisplayName => {
  const carrierName = getCarrierNameFromProduct(product);
  const productName = getProductNameFromProduct(product);
  const carrierDisplayName = getCarrierDisplayName(carrierName);
  const productDisplayName = getProductDisplayName(productName);

  return `${carrierDisplayName} ${productDisplayName}`;
};

/**
 * Returns LOB based on a string of known products passed as a URL query param,
 * otherwise returns null.
 */
export const getLineOfBusinessFromQuery = (
  query: string | undefined | null,
): LineOfBusiness | null => {
  switch (query?.toLowerCase()) {
    case 'auto':
      return LineOfBusiness.AUTO;
    case 'home':
    case 'homespecial':
      return LineOfBusiness.HOME;
    case 'rent':
    case 'renters':
      return LineOfBusiness.RENTERS;
    case 'bundle':
    case 'all':
      return LineOfBusiness.BUNDLE;
    case 'bundle_auto_renters':
      return LineOfBusiness.BUNDLE_AUTO_RENTERS;
    default:
      return null;
  }
};

/**
 * Returns LOB based on an array of known products
 * otherwise will return null
 */
export const getLineOfBusinessFromOfferProductSelected = (
  offerProductSelected: Product[],
): LineOfBusiness | null => {
  // If no products available return null
  if (!offerProductSelected.length) return null;

  // Create an array of unique product types
  const uniqueProductValues = offerProductSelected
    .map((item) => item.split('.')[1])
    .reduce((acc: string[], value) => {
      if (!acc.includes(value)) acc.push(value);

      return acc;
    }, []);
  // Determing LOB based on length of products and unique products
  switch (uniqueProductValues.length) {
    // If only 1 product we assume monoline products
    case 1:
      switch (uniqueProductValues[0]) {
        case 'auto':
          return LineOfBusiness.AUTO;
        case 'home':
          return LineOfBusiness.HOME;
        case 'renters':
          return LineOfBusiness.RENTERS;
        default:
          return null;
      }
    // If there are two unique products we must determing what type of bundle it is.
    case 2:
      if (uniqueProductValues.includes('auto') && uniqueProductValues.includes('home')) {
        return LineOfBusiness.BUNDLE;
      } else if (uniqueProductValues.includes('auto') && uniqueProductValues.includes('renters')) {
        return LineOfBusiness.BUNDLE_AUTO_RENTERS;
      } else {
        return null;
      }
    default:
      return null;
  }
};

const SUPPORTED_LOB = new Set([
  LineOfBusiness.AUTO,
  LineOfBusiness.HOME,
  LineOfBusiness.RENTERS,
  LineOfBusiness.BUNDLE,
  LineOfBusiness.BUNDLE_AUTO_RENTERS,
]);

export const isLineOfBusinessSupported = (
  lineOfBusiness: unknown,
): lineOfBusiness is LineOfBusiness => SUPPORTED_LOB.has(lineOfBusiness as LineOfBusiness);

const lineOfBusinessProperty = new Set<LineOfBusinessProperty>([
  LineOfBusiness.HOME,
  LineOfBusiness.RENTERS,
]);

export const isLineOfBusinessProperty = (
  lineOfBusiness: unknown,
): lineOfBusiness is LineOfBusinessProperty => lineOfBusinessProperty.has(lineOfBusiness);

const lineOfBusinessBundled = new Set<LineOfBusinessBundled>([
  LineOfBusiness.BUNDLE,
  LineOfBusiness.BUNDLE_AUTO_RENTERS,
]);

export const isLineOfBusinessBundle = (
  lineOfBusiness: unknown,
): lineOfBusiness is LineOfBusinessBundled => lineOfBusinessBundled.has(lineOfBusiness);

const lineOfBusinessAuto = new Set<LineOfBusinessAuto>([
  LineOfBusiness.AUTO,
  LineOfBusiness.BUNDLE,
  LineOfBusiness.BUNDLE_AUTO_RENTERS,
]);

export const isLineOfBusinessAuto = (
  lineOfBusiness: unknown,
): lineOfBusiness is LineOfBusinessAuto => lineOfBusinessAuto.has(lineOfBusiness);

const lineOfBusinessToLineOfBusinessUnbundled: Record<LineOfBusiness, LineOfBusinessUnbundled[]> = {
  [LineOfBusiness.AUTO]: [LineOfBusiness.AUTO],
  [LineOfBusiness.HOME]: [LineOfBusiness.HOME],
  [LineOfBusiness.BUNDLE]: [LineOfBusiness.AUTO, LineOfBusiness.HOME],
  [LineOfBusiness.RENTERS]: [LineOfBusiness.RENTERS],
  [LineOfBusiness.BUNDLE_AUTO_RENTERS]: [LineOfBusiness.AUTO, LineOfBusiness.RENTERS],
};

/** Returns an array of unbundled line of business(es) based on a bundled line of business parameter. An array either includes one monoline line of business item (when parameter itself is monoline) or multiple monoline line of business items (when parameter is a bundle). */
export const getLineOfBusinessUnbundledFromLineOfBusiness = (
  lineOfBusiness: unknown,
): LineOfBusinessUnbundled[] =>
  (isLineOfBusinessSupported(lineOfBusiness) &&
    lineOfBusinessToLineOfBusinessUnbundled[lineOfBusiness]) ||
  (emptyArray as unknown as LineOfBusinessUnbundled[]);

/** Returns a line of business based on an array of unbundled line of business(es) parameter. The array includes only one or multiple monoline line of business items. Result will be either a monoline or a bundle line of business enum value. */
export const getBundledLobFromUnbundledLobs = (linesOfBusiness: unknown): LineOfBusiness | null => {
  const isLinesOfBusinessArray = Array.isArray(linesOfBusiness) && linesOfBusiness.length >= 1;
  if (!isLinesOfBusinessArray) return null;

  const hasAuto = linesOfBusiness.includes(LineOfBusiness.AUTO);
  const hasHome = linesOfBusiness.includes(LineOfBusiness.HOME);
  const hasRenters = linesOfBusiness.includes(LineOfBusiness.RENTERS);

  if (hasAuto && hasHome) return LineOfBusiness.BUNDLE;
  if (hasAuto && hasRenters) return LineOfBusiness.BUNDLE_AUTO_RENTERS;
  if (hasAuto || hasHome || hasRenters) return linesOfBusiness[0];

  return null;
};

const unbundledLobToProductName: Record<LineOfBusinessUnbundled, ProductName> = {
  [LineOfBusiness.AUTO]: 'auto',
  [LineOfBusiness.HOME]: 'home',
  [LineOfBusiness.RENTERS]: 'renters',
};

export const getProductNamesFromLob = (lineOfBusiness: unknown): ProductName[] => {
  const unbundledLobs = getLineOfBusinessUnbundledFromLineOfBusiness(lineOfBusiness);

  return unbundledLobs.map((unbundledLob) => unbundledLobToProductName[unbundledLob]);
};

export const getReducedProductNamesFromLob = (lineOfBusiness: unknown): ReducedProductName[] => {
  const productNames = getProductNamesFromLob(lineOfBusiness);
  const reducedProductNames = productNames.map(getReducedProductNameFromProductName);

  return reducedProductNames;
};

export const getProductNameFromUnbundledLob = (
  lineOfBusiness: LineOfBusinessUnbundled,
): ProductName => unbundledLobToProductName[lineOfBusiness];

export const getProductDisplayNameFromUnbundledLob = (
  lineOfBusiness: LineOfBusinessUnbundled,
): ProductDisplayName => {
  const productName = getProductNameFromUnbundledLob(lineOfBusiness);

  return getProductDisplayName(productName);
};

const productNameToUnbundledLob: Record<ProductName, LineOfBusinessUnbundled> = {
  auto: LineOfBusiness.AUTO,
  home: LineOfBusiness.HOME,
  renters: LineOfBusiness.RENTERS,
};

export const getUnbundledLobFromProductName = (productName: ProductName): LineOfBusinessUnbundled =>
  productNameToUnbundledLob[productName];

export const productMatchesLOB = (lob: LineOfBusiness, product: Product): boolean => {
  switch (lob) {
    case LineOfBusiness.AUTO:
      return isProductAuto(product);
    case LineOfBusiness.HOME:
      return isProductHome(product);
    case LineOfBusiness.BUNDLE:
      return isProductAuto(product) || isProductHome(product);
    case LineOfBusiness.RENTERS:
      return isProductRenters(product);
    case LineOfBusiness.BUNDLE_AUTO_RENTERS:
      return isProductAuto(product) || isProductRenters(product);
    default:
      return false;
  }
};

const lineOfBusinessToProductNames: Record<LineOfBusiness, ProductName[]> = {
  [LineOfBusiness.AUTO]: ['auto'],
  [LineOfBusiness.HOME]: ['home'],
  [LineOfBusiness.RENTERS]: ['renters'],
  [LineOfBusiness.BUNDLE]: ['auto', 'home'],
  [LineOfBusiness.BUNDLE_AUTO_RENTERS]: ['auto', 'renters'],
};

export const getProductNamesFromLineOfBusiness = (lineOfBusiness: LineOfBusiness): ProductName[] =>
  lineOfBusinessToProductNames[lineOfBusiness];
