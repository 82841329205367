export interface TpiMockData {
  tpiTitle: string;
  loanNumber: string;
  billToEscrow: string;
  order: string;
  pending: string;
}

export const tpiMockData: TpiMockData[] = [
  {
    tpiTitle: 'Tennesse Credit Union',
    loanNumber: '032145699789874645',
    billToEscrow: 'Yes',
    order: 'FIRST',
    pending: 'No',
  },
  {
    tpiTitle: 'Chattanooga Credit Union',
    loanNumber: '032145699789123456',
    billToEscrow: 'No',
    order: 'SECOND',
    pending: 'No',
  },
  {
    tpiTitle: 'Chattanooga Credit Union',
    loanNumber: '032145699789123456',
    billToEscrow: 'No',
    order: 'PENDING',
    pending: 'Yes',
  },
];
