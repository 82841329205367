import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ThirdPartyInterestForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    marginTop: 0,
  },
  next: {
    padding: '16px 30px 16px 30px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  content: {
    margin: 0,
    width: '100%',
    color: theme.palette.error.main,
  },
  card: {
    padding: '12px 16px 12px 16px',
    width: '100%',
    border: `1px solid ${theme.palette.other.divider}`,
    borderRadius: '4px',
    marginTop: 12,
  },
  contentTitle: {
    ...theme.typography.body4,
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.body3,
    },
  },
  contentSubTitle: { ...theme.typography.body2, color: theme.palette.error.main },
  columnRight: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
  divider: {
    margin: '40px 0px 40px 0px',
    width: '100%',
  },
  productTitleSection: {
    display: 'flex',
    alignItems: 'center',
  },
  productIcon: {
    '& svg': {
      width: 30,
      height: 30,
      ...theme.mixins.setColorSvg(theme.palette.icon.hover),
      marginRight: 15,
    },
  },
  productTitle: theme.typography.body4Bold,
  plusIcon: {
    ...theme.mixins.setColorSvg(theme.palette.text.link),
    width: '18px',
    height: '18px',
  },
  subTitle: {
    marginTop: 5,
  },
  optional: {
    color: theme.palette.text.tertiary,
  },
}));
