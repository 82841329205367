export const enum PageFlow {
  NOT_DEFINED,
  A,
  B,
  C,
  D,
  E,
  F,
  G,
  H,
  I,
  J,
  K,
  L,
  M,
  N,
  O,
  P,
  Q,
  R,
  S,
  T,
  U,
  V,
  W,
  X,
  Y,
  Z,
  AA,
  AB,
  MI,
  MIA,
  MIB,
  MIC,
  MID,
  MIE,
}

export const enum PagePath {
  DEFAULT = '/',
  ERROR = '/error',
  BUNDLE_DISCOUNTS = '/quote/discounts/bundle',
  AUTO_DISCOUNTS_SHORT = '/quote/auto/shortdiscounts',
  AUTO_DISCOUNTS = '/quote/auto/discounts',
  HOME_LOSSES = '/quote/home/losses',
  HOME_DISCOUNTS = '/quote/home/discounts',
  RENTERS_DISCOUNTS = '/quote/renters/discounts',
  RENTERS_PROPERTY_DETAILS = '/quote/renters/propertydetails',
  RENTERS_LOSSES = '/quote/renters/losses',
  LANDING = '/quote/landing',
  LANDING_RETRIEVE = '/quote/landing?showRetrieve=true',
  MEMBERSHIP = '/quote/membership',
  LOADING = '/quote/loading/:product',
  PERSON = '/quote/person',
  PERSON_ADDRESS = '/quote/person/address',
  ADD_VEHICLE_PROFILE = '/quote/auto/vehicle',
  ADD_EDIT_VEHICLES = '/quote/auto/addeditvehicles',
  AUTO_PRIOR_INSURANCE = '/quote/auto/priorinsurance',
  AUTO_LOSSES_AND_VIOLATIONS = '/quote/auto/lossesandviolations',
  VEHICLE_PROFILE = '/quote/auto/vehicle/:id?',
  DRIVER_PROFILE = '/quote/auto/driver/:id?',
  ADD_DRIVER_PROFILE = '/quote/auto/driver',
  ADD_EDIT_DRIVERS = '/quote/auto/addeditdrivers',
  EDIT_AUTO_PROFILE = '/quote/auto/profile',
  DRIVER_ASSIGNMENT = '/quote/auto/assignment',
  DRIVER_ASSIGNMENT_QUOTES = '/quote/auto/assignment?referencePage=quotes',
  VEHICLE_OWNERSHIP_DATE = '/quote/auto/vehicleownershipdate',
  QUOTES_LOADING = '/quote/quotes?loading',
  QUOTES = '/quote/quotes',
  AUTO_DELTA = '/app/auto/delta',
  HOME_DELTA = '/app/home/delta',
  RENTERS_DELTA = '/app/renters/delta',
  THIRD_PARTY_REPORTS = '/app/thirdpartyreports',
  THIRD_PARTY_INTEREST = '/app/tpi',
  ADD_THIRD_PARTY_INTEREST = '/app/tpi/:id?',
  SECONDARY_NAMED_INSURED = '/app/sni',
  COVERAGES = '/app/coverages',
  CHECKOUT = '/app/checkout',
  CHECKOUT_RETRY = '/app/checkout/retry',
  CHECKOUT_ERROR = '/app/checkout/error',
  POST_BIND = '/app/postbind',
  RATING_CRITERIA = '/app/ratingcriteria',
  HOME_ELIGIBILITY = '/quote/home/eligibility',
  HOME_BASIC = '/quote/home/basic',
  HOME_INTERIOR = '/quote/home/interior',
  HOME_EXTERIOR = '/quote/home/exterior',
  HOME_OTHER_STRUCTURES = '/quote/home/otherstructures',
  HOME_MARKET_VALUE = '/quote/home/marketvalue',
  HOME_PREFILL_SUMMARY = '/review-home-details',
  SELECT_PRODUCT = '/quote/selectproduct',
  // Static
  ACCESSIBILITY = '/accessibility',
  FAQ = '/faq',
  PARTICIPATING_INSURERS = '/participatinginsurers',
  PRIVACY_POLICY = '/privacypolicy',
  TERMS_OF_USE = '/termsofuse',
  USE_OF_CREDIT_DISCLOSURE = '/useofcreditdisclosure',
  // Agent
  AGENT_LOGIN = '/agent/login',
  AGENT_DASHBOARD = '/agent/dashboard',
  TRANSITION = '/quote/transition',
}
