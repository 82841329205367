import { waitForCondition } from '@ecp/utils/common';
import { Queue } from '@ecp/utils/queue';

import { env } from '@ecp/env';
import {
  CURRENT_CONFIG_PAGE,
  CURRENT_PAGE,
  CURRENT_PAGE_ANALYTICS,
  NavStatus,
  OFFER_PRODUCTS_SELECTED,
  PrefillFlow,
  PRODUCT_LOB_USER_SELECTION,
} from '@ecp/features/sales/shared/constants';
import { PagePath } from '@ecp/features/sales/shared/routing';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';
import type { Answers } from '@ecp/features/sales/shared/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import {
  getLineOfBusinessFromOfferProductSelected,
  type Product,
} from '@ecp/features/shared/product';

import { getAllConfigPagePaths, getAllPageFlows, getPageFlowPaths } from '../config';
import {
  getDalSessionId,
  getHomePrefillFlow,
  getPrimaryInsuredAddressLock,
  getPrimaryInsuredPersonLock,
} from '../inquiry/selectors';
import { updateAnswers } from '../inquiry/thunks';
import { getSapiAnalyticsSelectedOfferEventDetail } from '../offers/selectors';
import { setNavCurrentPageChanged, setPageStatusChanged } from './actions';
import { getCurrentPage, getNavTracking } from './selectors';

const queue = new Queue();

/** NOTE: Don't await on this function if inquiry might not be ready by the time you call this function. */
export const updateNavCurrentPage =
  ({
    page,
    trackCurrentPageAnalytics = true,
  }: {
    page: string;
    trackCurrentPageAnalytics?: boolean;
  }): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    if (page === getCurrentPage(getState())) return;
    await queue.add({
      work: async () => {
        const inquiryExists = await waitForCondition({
          condition: () => Boolean(getDalSessionId(getState())),
          interval: 500,
          limit: 10000,
        });

        // If inquiry still doesn't exist after 10s time limit, this should not really happen
        if (!inquiryExists) return;

        dispatch(setNavCurrentPageChanged(page));
        dispatch(updatePageStatus(NavStatus.VISITED, page));

        if (trackCurrentPageAnalytics) {
          await dispatch(updateCurrentPageAnswers({ page }));
        }
      },
    });
  };

export const updateCurrentPageAnswers =
  ({ page }: { page: string }): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const doesConfigIncludeCurrentPage = getAllConfigPagePaths(getState()).includes(page);

    const answers: Answers = {
      [CURRENT_PAGE]: page,
      [CURRENT_PAGE_ANALYTICS]: page,
      ...(doesConfigIncludeCurrentPage && { [CURRENT_CONFIG_PAGE]: page }),
    };

    await dispatch(updateAnswers({ answers }));
  };

export const updateOfferProductChanged =
  (offerProductsSelected: Product[]): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    await dispatch(
      updateAnswers({ answers: { [OFFER_PRODUCTS_SELECTED]: offerProductsSelected } }),
    );

    trackSapiAnalyticsEvent({
      element: 'choice.quoteSummaryPage.finishAndBuyButton',
      event: 'click',
      eventDetail: getSapiAnalyticsSelectedOfferEventDetail(getState()),
    });
  };

export const updateProductLobUserSelection =
  (offerProductsSelected: Product[]): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    // Determine the line of business based on the selected offer products
    const lineOfBusinessSelection =
      getLineOfBusinessFromOfferProductSelected(offerProductsSelected);
    await dispatch(
      updateAnswers({
        answers: {
          [PRODUCT_LOB_USER_SELECTION]: lineOfBusinessSelection,
        },
      }),
    );
  };

// Sets Home Basic, exterior, Interior page status to VALID for Agent experience in Home short flow
const forceValidStatusForHomeShortFlows =
  (pagePaths: PagePath[]): ThunkAction<void> =>
  (dispatch, getState) => {
    dispatch(
      forceValidPageStatus(
        pagePaths,
        !!env.static.isAgent && getHomePrefillFlow(getState()) === PrefillFlow.SHORT,
      ),
    );
  };

const forceValidPageStatusWhenPNILock =
  (pagePaths: PagePath[]): ThunkAction<void> =>
  (dispatch, getState) => {
    dispatch(
      forceValidPageStatus(
        pagePaths,
        getPrimaryInsuredPersonLock(getState()) && getPrimaryInsuredAddressLock(getState()),
      ),
    );
  };

const forceValidPageStatus =
  (pagePaths: PagePath[], condition: boolean): ThunkAction<void> =>
  (dispatch, getState) => {
    const navTracking = getNavTracking(getState());
    pagePaths.forEach((pagePath) => {
      if (navTracking[pagePath]?.status !== NavStatus.VALID && condition) {
        dispatch(
          setPageStatusChanged({
            path: pagePath,
            tracking: {
              status: NavStatus.VALID,
              inProgress: navTracking[pagePath]?.inProgress ?? false,
            },
          }),
        );
      }
    });
  };

export const updatePageStatus =
  (status?: NavStatus, path?: string, inProgress = true): ThunkAction<void> =>
  (dispatch, getState) => {
    const currentPage = path ?? getCurrentPage(getState());

    // This code will be removed in EDSP-8506.
    // line 169 will be 'dispatch(forceValidPageStatusWhenPNILock([PagePath.LANDING, PagePath.PERSON]));'
    const pniLockPages = [PagePath.LANDING, PagePath.PERSON];

    // If pni locks are set, force valid page status since forms can't be updated.
    dispatch(forceValidPageStatusWhenPNILock(pniLockPages));

    dispatch(
      forceValidStatusForHomeShortFlows([
        PagePath.HOME_BASIC,
        PagePath.HOME_EXTERIOR,
        PagePath.HOME_INTERIOR,
      ]),
    );

    if (currentPage)
      dispatch(
        setPageStatusChanged({
          path: currentPage,
          tracking: {
            status: status ?? NavStatus.VISITED,
            inProgress: inProgress,
          },
        }),
      );
  };

export const updatePartialPageStatus =
  (status: NavStatus, partialPath: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const navTracking = getNavTracking(getState());
    Object.keys(navTracking)
      .filter((key) => key.includes(partialPath))
      .forEach((fullPath) => dispatch(updatePageStatus(status, fullPath)));
  };

export const makePreviousPagesValid =
  (page: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const allPageFlows = getAllPageFlows(getState());
    const allPaths = allPageFlows.flatMap((pageFlow) => getPageFlowPaths(pageFlow));
    const filteredPaths = allPaths.slice(0, allPaths.indexOf(page));

    filteredPaths.forEach((path) => dispatch(updatePageStatus(NavStatus.VALID, path)));
  };
