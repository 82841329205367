import type { QuestionsUIMetadata } from '@ecp/features/sales/shared/types';
import {
  GraphicHomeDetailAttachedImageUrl,
  GraphicHomeDetailBasementImageUrl,
  GraphicHomeDetailBuiltinImageUrl,
  GraphicHomeDetailCarportImageUrl,
  GraphicHomeDetailFlatImageUrl,
  GraphicHomeDetailGableImageUrl,
  GraphicHomeDetailHipImageUrl,
  GraphicHomeDetailNoneImageUrl,
  GraphicHomeDetailNoSlopeImageUrl,
  GraphicHomeDetailPrimaryImageUrl,
  GraphicHomeDetailsInterWallTypeAdobeImageUrl,
  GraphicHomeDetailsInterWallTypeConcreteBlockImageUrl,
  GraphicHomeDetailsInterWallTypeStudsOnlyImageUrl,
  GraphicHomeDetailSlopeImageUrl,
  GraphicHomeDetailsRoofMaterialAluminumCorrugatedImageUrl,
  GraphicHomeDetailsRoofMaterialArchitecturalShinglesImageUrl,
  GraphicHomeDetailsRoofMaterialAsphaltShinglesImageUrl,
  GraphicHomeDetailsRoofMaterialClayTileImageUrl,
  GraphicHomeDetailsRoofMaterialCompositionShinglesImageUrl,
  GraphicHomeDetailsRoofMaterialConcreteTileImageUrl,
  GraphicHomeDetailsRoofMaterialFoamImageUrl,
  GraphicHomeDetailsRoofMaterialRolledRoofImageUrl,
  GraphicHomeDetailsRoofMaterialRubberImageUrl,
  GraphicHomeDetailsRoofMaterialSlateImageUrl,
  GraphicHomeDetailsRoofMaterialSteelSidingImageUrl,
  GraphicHomeDetailsRoofMaterialTarOrGravelImageUrl,
  GraphicHomeDetailsRoofMaterialWoodShinglesShakesImageUrl,
  GraphicHomeDetailsWallConstructionBrickOnMasonryImageUrl,
  GraphicHomeDetailsWallConstructionConcreteBlockImageUrl,
  GraphicHomeDetailsWallConstructionEIFSSyntheticStuccoImageUrl,
  GraphicHomeDetailsWallConstructionHardboardSidingImageUrl,
  GraphicHomeDetailsWallConstructionHardyPlankCementFiberImageUrl,
  GraphicHomeDetailsWallConstructionLogsImageUrl,
  GraphicHomeDetailsWallConstructionSteelSidingImageUrl,
  GraphicHomeDetailsWallConstructionStuccoOnFrameImageUrl,
  GraphicHomeDetailsWallConstructionStuccoOnMasonryImageUrl,
  GraphicHomeDetailsWallConstructionVinylSidingImageUrl,
  GraphicHomeDetailsWallConstructionWoodShakesImageUrl,
  GraphicHomeDetailsWallConstructionWoodSidingImageUrl,
  IconUIQuestionImageUrl,
} from '@ecp/themes/base';
import {
  GraphicHomeDetailsRoofMaterialTLockUrl,
  GraphicHomeDetailsWallConstructionBrickVeneerImageUrl,
  GraphicHomeDetailsWallConstructionEarthHomeImageUrl,
  GraphicHomeDetailsWallConstructionSolidBrickImageUrl,
  GraphicHomeDetailsWallConstructionSolidLogsImageUrl,
  GraphicHomeDetailsWallConstructionSolidStoneImageUrl,
  GraphicHomeDetailsWallConstructionStoneVeneerImageUrl,
} from '@ecp/themes/partners/amfam-adv';

import propertyQuestionsUIMetadataBase from './propertyQuestionsUIMetadata.js';

const propertyQuestionsUIMetadata: QuestionsUIMetadata = {
  ...propertyQuestionsUIMetadataBase,
  'property.addOtherStructuresInd': {
    title: 'Do you want to Add to Schedule Detached Structures?',
  },
  'property.businessOnPremises': {
    title: 'Is the dwelling used for a business, office, school or studio?',
  },
  'businessOnPremises.<id>.businessType': {
    title: 'What type of business is it?',
    options: [
      {
        value: 'SMALL_SERVICE',
        label: 'Small Service',
      },
      {
        value: 'OFFICE_FOR_BUSINESS',
        label: 'Office for Business or Professional Purposes',
      },
      {
        value: 'MERCHANDISE_STORAGE',
        label: 'Merchandise Storage',
      },
      {
        value: 'PRIVATE_SCHOOL',
        label: 'Private School',
      },
      {
        value: 'STUDIO',
        label: 'Studio',
      },
      {
        value: 'HOME_DEMONSTRATION',
        label: 'Home Demonstration',
      },
      {
        value: 'OTHER',
        label: 'Other',
      },
    ],
  },
  'businessOnPremises.<id>.businessDescription': {
    title: 'Enter the type of business.',
  },
  'businessOnPremises.<id>.commercialCoverageInd': {
    title: 'Does the business have commercial insurance coverage?',
  },
  'businessOnPremises.<id>.employeeInd': {
    title: 'Does the business have employees, other than a named insured?',
  },
  'property.dayCareOnPremisesInd': {
    title: 'Is the home used as a business or daycare?',
  },
  'foundation.<id>.foundationType': {
    title: "What’s the home's primary foundation type?",
    options: [
      {
        sortingKey: 5,
        value: 'FOUNDATION.FOUNDATION_TYPE.SLAB',
        label: 'Slab',
        noIcon: true,
      },
      {
        sortingKey: 2,
        value: 'FOUNDATION.FOUNDATION_TYPE.ENCLOSED_CRAWL_SPACE_PIER_AND_BEAM',
        label: 'Crawl Space',
        noIcon: true,
      },
      {
        sortingKey: 1,
        value: 'FOUNDATION.FOUNDATION_TYPE.BASEMENT_UNDERGROUND_BELOW_GRADE',
        label: 'Basement',
        noIcon: true,
      },
      {
        sortingKey: 4,
        value: 'FOUNDATION.FOUNDATION_TYPE.POST_PIER',
        label: 'Post / Pier & Grade Beam',
        noIcon: true,
      },
      {
        sortingKey: 3,
        value: 'FOUNDATION.FOUNDATION_TYPE.ENGINEERED_PIER',
        label: 'Engineered Piers',
        noIcon: true,
      },
      {
        sortingKey: 6,
        value: 'FOUNDATION.FOUNDATION_TYPE.OTHER',
        label: 'Other',
        noIcon: true,
      },
    ],
  },
  'property.foundation.percentageFinished': {
    title: 'What percentage of the basement is finished?',
  },
  'foundation.<id>.otherFoundationTypeDescription': {
    title: 'Describe the other type of foundation.',
  },
  'foundation.<id>.finishedBasementLivingAreaSQF': {
    title: 'Total Below Grade Finished Sq. Footage',
  },
  'property.garage.type': {
    title: `What's the home's garage type?`,
    options: [
      {
        label: 'Attached Garage',
        value: 'ATTACHED_EXT',
        image: GraphicHomeDetailAttachedImageUrl,
        alt: 'Attached Garage',
        helpText: 'Directly attached to the house and no living area above it.',
      },
      {
        label: 'Basement (Below Grade)',
        value: 'BASEMENT_EXT',
        image: GraphicHomeDetailBasementImageUrl,
        alt: 'Basement (Below Grade)',
        helpText: 'Built partially below ground with the entrance even with the basement floor.',
      },
      {
        label: 'Built-in (Living Area Above It)',
        value: 'BUILTIN_EXT',
        image: GraphicHomeDetailBuiltinImageUrl,
        alt: 'Built-in (Living Area Above It)',
        helpText: 'Built into the residence at ground level with a living area above it.',
      },
      {
        label: 'Carport - Attached',
        value: 'CARPORT_EXT',
        image: GraphicHomeDetailCarportImageUrl,
        alt: 'Carport - Attached',
        helpText:
          'A flat or gable roofed shelter supported by posts, generally attached to the residence on one side.',
      },
      {
        label: 'None',
        value: 'NONE_EXT',
        image: GraphicHomeDetailNoneImageUrl,
        alt: 'None',
        helpText: 'No garage present at home.',
      },
    ],
  },
  'property.fullBaths': {
    title: 'How many bathrooms are in the home?',
  },
  'property.garage.size': {
    title: 'What size is the garage?',
    options: [
      {
        label: '1',
        value: 'ONE',
      },
      {
        label: '1.5',
        value: 'ONEHALF',
      },
      {
        label: '2',
        value: 'TWO',
      },
      {
        label: '2.5',
        value: 'TWOHALF',
      },
      {
        label: '3',
        value: 'THREE',
      },
      {
        label: '3.5',
        value: 'THREEHALF',
      },
      {
        label: '4',
        value: 'FOUR',
      },
      {
        label: '4.5',
        value: 'FOURHALF',
      },
      {
        label: '5',
        value: 'FIVE',
      },
      {
        label: '5.5',
        value: 'FIVEHALF',
      },
      {
        label: '6',
        value: 'SIX',
      },
      {
        label: '6.5',
        value: 'SIXHALF',
      },
      {
        label: '7',
        value: 'SEVEN',
      },
      {
        label: '7.5',
        value: 'SEVENHALF',
      },
      {
        label: '8',
        value: 'EIGHT',
      },
      {
        label: '8.5+',
        value: 'EIGHTHALFPLUS',
      },
    ],
  },
  'property.hasSolidFuelInd': {
    title:
      'Is there solid heating fuel in any garage or structure with a vehicle or flammable fuel storage?',
  },
  'property.heatingSystem': {
    title: 'Does the home have a heating system?',
  },
  'property.heating.primaryType': {
    title: `What’s the home's primary heating type?`,
    options: [
      {
        label: 'Central Heating Systems',
        value: 'CENTRAL',
      },
      {
        label: 'Area Heating Units',
        value: 'AREA',
      },
      {
        label: 'No Heating System',
        value: 'NONE',
      },
    ],
  },
  'property.heating.fuelType': {
    title: `What fuel does the heating system use?`,
  },
  'property.heating.fuelTypeDescription': {
    title: `Describe the fuel type.`,
  },
  'property.heating.systemType': {
    title: `What is the home's primary heating system?`,
  },
  'secondaryHeatingSource.<id>.fuelType': {
    title: `What fuel does the heating system use?`,
  },
  'secondaryHeatingSource.<id>.fuelTypeDescription': {
    title: `Describe the fuel type.`,
  },
  'secondaryHeatingSource.<id>.systemType': {
    title: `What is the home's secondary heating system?`,
  },
  'property.isHistoricHome': {
    title: 'Is this an Historic Home?',
    helpText: 'Select "Yes" if your property is a historic home.',
  },
  'property.historicHomeDesignation': {
    title: 'What type of historic home is the home?',
  },
  'property.homeBuiltOnSlope': {
    title: 'Is the home located on a hillside with a steep slope that is greater than 30 degrees?',
    options: [
      {
        value: 'true',
        label: 'Yes',
        image: GraphicHomeDetailSlopeImageUrl,
        alt: 'Yes, built on slope',
      },
      {
        value: 'false',
        label: 'No',
        image: GraphicHomeDetailNoSlopeImageUrl,
        alt: 'No, not built on slope',
      },
    ],
  },
  'property.highRiskDogOnPremisesInd': {
    title: 'Does [pniFirstName] have any of the following high risk dogs?',
    subtitle:
      'Including Akita, American pit bull terrier (also known as an American Staffordshire or Staffordshire terrier), Chow, Rottweiler, Wolf Hybrid, a mix that includes any of the previously listed breeds, or an active or retired police, K9, or other government agency dog.',
    stateOptions: {
      NV: {
        title:
          'Are there any dogs on your residence premises that are known to be dangerous or vicious pursuant to NRS 202.500?',
        subtitle: '',
      },
    },
  },
  'property.higherThanThreeStories': {
    title: 'Is the rental unit in a building higher than 3 stories?',
  },
  'property.noOfDaysRented': {
    title: 'How many days in a year is the home rented?',
  },
  'property.occupyByMoreThanOneResidentInd': {
    title: 'Is [pniFirstName] occupying more than one residence?',
  },
  'property.numberOfStories': {
    title: 'Excluding the basement, how many stories is the home?',
    options: [
      {
        label: '1',
        value: 'NUMBER_OF_STORIES.ONE',
      },
      {
        label: '1.5',
        value: 'NUMBER_OF_STORIES.ONE_AND_HALF',
      },
      {
        label: '2',
        value: 'NUMBER_OF_STORIES.TWO',
      },
      {
        label: '2.5',
        value: 'NUMBER_OF_STORIES.TWO_AND_HALF',
      },
      {
        label: '3',
        value: 'NUMBER_OF_STORIES.THREE',
      },
      {
        label: '3.5',
        value: 'NUMBER_OF_STORIES.THREE_AND_HALF',
      },
      {
        label: '4',
        value: 'NUMBER_OF_STORIES.FOUR',
      },
      {
        label: '4.5',
        value: 'NUMBER_OF_STORIES.FOUR_AND_HALF',
      },
      {
        label: '5',
        value: 'NUMBER_OF_STORIES.FIVE',
      },
    ],
  },
  'property.otherStructuresBlanketCoverageInd': {
    title: 'Do you want other structures blanket coverage?',
  },
  'property.occupancyType': {
    title: 'How will this home be used?',
  },
  'property.unrepairedMineSubDamageInd': {
    title: 'Does the home or any detached structure have unrepaired mine subsidence damage?',
  },
  'property.rentedToOthersInd': {
    title: 'Is the home rented to others?',
  },
  'property.noOfMortgages': {
    title: 'How many mortgages are on the home?',
  },
  'property.renovation.plumbingReplacedYear': {
    title: 'What Year was your Plumbing Installed/Replaced?',
  },
  'property.renovation.renovationLast15Years': {
    title:
      'Have the home’s plumbing, electrical and heating systems been renovated within the last 15 years?',
  },
  'property.renovation.renovationYear': {
    title: 'When was the home renovated?',
  },
  'property.renovation.renovationDocumentProvidedInd': {
    title: 'Has documentation been provided for the last renovation?',
  },
  'property.construction.underConstructionInd': {
    title: 'Is this a new home under construction?',
  },
  'property.construction.constructionStartDate': {
    title: 'Above-Ground Construction Start Date',
  },
  'property.construction.constructionEstimatedCompletionDate': {
    title: 'Estimated Completion Date',
  },
  'property.construction.constructionActualCompletionDate': {
    title: 'Actual Completion Date (optional)',
  },
  'property.construction.constructionCost': {
    title: 'Estimated Construction Cost',
  },

  'property.roof.material': {
    title: 'What’s the material on the roof?',
    options: [
      {
        sortingKey: 1,
        label: 'Architectural Shingles',
        value: 'ROOF.MATERIAL.ARCHITECTURAL_SHINGLES',
        image: GraphicHomeDetailsRoofMaterialArchitecturalShinglesImageUrl,
        alt: 'Architectural shingles',
        helpText:
          'Factory installed designer asphalt/fiberglass shingles featuring multilayered construction, where random tabs or pads are applied to a base shingle to achieve added dimension and replicate the look of wood shakes. These shingles are sometimes referred to as laminated or three dimensional shingles.',
      },
      {
        sortingKey: 2,
        label: 'Built-up/Tar and Gravel',
        value: 'ROOF.MATERIAL.BUILT_UP_TAR_AND_GRAVEL',
        image: GraphicHomeDetailsRoofMaterialTarOrGravelImageUrl,
        alt: 'Built-Up/tar and gravel',
        helpText:
          'Typically installed on flat roofs. A layer of rocks or marble chips can be placed on top to protect the tar/gravel from the sun and add weight to protect the roof during a windstorm.',
      },
      {
        sortingKey: 5,
        label: 'Composition Shingles',
        value: 'ROOF.MATERIAL.COMPOSITION_SHINGLES',
        image: GraphicHomeDetailsRoofMaterialCompositionShinglesImageUrl,
        alt: 'Composition shingles',
        helpText:
          'Made from a combination of materials such as asphalt or asbestos, tar paper, slate, shake, laminate and wood.',
      },
      {
        sortingKey: 4,
        label: 'Clay Tile',
        value: 'ROOF.MATERIAL.CLAY_TILE',
        image: GraphicHomeDetailsRoofMaterialClayTileImageUrl,
        alt: 'Clay tile',
        helpText:
          'A decorative material that is available in a variety of colors, shapes, patterns and textures.',
      },
      {
        sortingKey: 6,
        label: 'Concrete Tile',
        value: 'ROOF.MATERIAL.CONCRETE_TILE',
        image: GraphicHomeDetailsRoofMaterialConcreteTileImageUrl,
        alt: 'Concrete tile',
        helpText:
          'Flat, barrel (or mission), and ""S"" tile are the common styles. It’s made primarily from sand, cement and water. The three main appearances are flat, low and high profile.',
      },
      {
        sortingKey: 9,
        label: 'Metal',
        value: 'ROOF.MATERIAL.METAL',
        image: GraphicHomeDetailsRoofMaterialAluminumCorrugatedImageUrl,
        alt: 'Metal',
        helpText:
          'A type of metal roof, which can be large metal paneled with heavily wavy ridges, smaller individual metal shingles or tiles, or standing seam panels, which have a seam at one end and a lock at the other, and are installed with clips.',
      },
      {
        sortingKey: 11,
        label: 'Rubber Membrane',
        value: 'ROOF.MATERIAL.RUBBER',
        image: GraphicHomeDetailsRoofMaterialRubberImageUrl,
        alt: 'Rubber Membrane',
        helpText:
          'A rubber roof is generally found on homes with a small pitch and is applied in rolls.',
      },
      {
        sortingKey: 12,
        label: 'Slate',
        value: 'ROOF.MATERIAL.SLATE',
        image: GraphicHomeDetailsRoofMaterialSlateImageUrl,
        alt: 'Slate',
        helpText:
          'Arranged in overlapping rows. Found in unfading or weathering colors. Unfading colors stay close to their original color and weathering colors change as they age.',
      },
      {
        sortingKey: 17,
        label: 'Wood Shakes/Shingles',
        value: 'ROOF.MATERIAL.WOOD_SHINGLES',
        image: GraphicHomeDetailsWallConstructionWoodShakesImageUrl,
        alt: 'Wood shakes',
        helpText:
          'Cedar, redwood, southern pine and other woods of different shapes are installed to create a decorative pattern. Prevalent in Cape Cod and Colonial homes. Typically, shakes are handmade.',
      },
      {
        sortingKey: 8,
        label: 'Foam',
        value: 'ROOF.MATERIAL.FOAM',
        image: GraphicHomeDetailsRoofMaterialFoamImageUrl,
        alt: 'Foam',
        helpText:
          'Insulating sprayed-on foam usually coated with an elastomeric membrane can be installed in various thicknesses to provide slope to drain or protect it from specified weather elements.',
      },
      {
        sortingKey: 10,
        label: 'Rolled Composition',
        value: 'ROOF.MATERIAL.ROLLED_ROOF_SINGLE_PLY',
        image: GraphicHomeDetailsRoofMaterialRolledRoofImageUrl,
        alt: 'Rolled Composition',
        helpText:
          'A flexible pre-manufactured membrane usually made from rubber or plastic materials. Single-ply roofing comes in large rolls and is glued or mechanically fastened to a roof and sealed at all seams.',
      },
      {
        sortingKey: 13,
        label: 'Sod/Eco',
        value: 'ROOF.MATERIAL.SOD',
        image: GraphicHomeDetailsRoofMaterialWoodShinglesShakesImageUrl,
        alt: 'Sod/Eco',
        helpText:
          'Cedar, redwood, southern pine and other woods of different shapes are installed to create a decorative pattern. Prevalent in Cape Cod and Colonial homes. Typically, shingles are factory made.',
      },
      {
        sortingKey: 15,
        label: 'Synthetic/Plastic Shingles',
        value: 'ROOF.MATERIAL.PLASTIC',
        image: GraphicHomeDetailsRoofMaterialAsphaltShinglesImageUrl,
        alt: 'Synthetic/Plastic Shingles',
        helpText:
          'Typically one layer of asphalt that is reinforced with fiberglass or organic felt and has 3 distinct breaks for each shingle. It creates a distinct symmetrical pattern resembling stacked bricks.',
      },
      {
        sortingKey: 3,
        label: 'Built-up/Tar without Gravel',
        value: 'ROOF.MATERIAL.BUILT_UP',
        image: GraphicHomeDetailsRoofMaterialAsphaltShinglesImageUrl,
        alt: 'Built-up/Tar without Gravel',
        helpText:
          'Typically one layer of asphalt that is reinforced with fiberglass or organic felt and has 3 distinct breaks for each shingle. It creates a distinct symmetrical pattern resembling stacked bricks.',
      },
      {
        sortingKey: 7,
        label: 'Fiber Cement Tile',
        value: 'ROOF.MATERIAL.FIBER_CEMENT',
        image: GraphicHomeDetailsRoofMaterialConcreteTileImageUrl,
        alt: 'Fiber Cement Tile',
        helpText:
          'Flat, barrel (or mission), and ""S"" tile are the common styles. It’s made primarily from sand, cement and water. The three main appearances are flat, low and high profile.',
      },
      {
        sortingKey: 14,
        label: 'Solar (Photovoltaic) Shingles',
        value: 'ROOF.MATERIAL.SOLAR',
        image: GraphicHomeDetailsRoofMaterialSteelSidingImageUrl,
        alt: 'Solar (Photovoltaic) Shingles',
        helpText:
          'Steel siding is resistant to rot, insects, and fire damage. It is stronger, heavier, and more resistant to denting than aluminum. Susceptible to rusting.',
      },
      {
        sortingKey: 16,
        label: 'T-Lock',
        value: 'ROOF.MATERIAL.T_LOCK',
        image: GraphicHomeDetailsRoofMaterialTLockUrl,
        alt: 'T-Lock',
        helpText: 'T-Lock Roof Material for SD and ND state',
      },

      {
        sortingKey: 18,
        label: 'Other',
        value: 'ROOF.MATERIAL.OTHER',
        image: IconUIQuestionImageUrl,
        alt: 'Other',
      },
    ],
  },
  'property.roof.materialDescription': {
    title: 'Describe the roof material.',
  },
  'property.roof.roofClass': {
    title: 'Is the roof impact resistant?',
    options: [
      {
        label: 'Not Applicable',
        value: 'NA',
      },
      {
        label: 'Class 3',
        value: 'CLASS_3',
      },
      {
        label: 'Class 4',
        value: 'CLASS_4',
      },
    ],
  },
  'property.roof.roofClassDocumentProvidedInd': {
    title: 'Has documentation been provided for the impact resistant roof?',
  },
  'property.roof.installOrReplace': {
    title: 'What year was the roof installed / replaced?',
  },
  'property.roof.shape': {
    title: `What’s the home's roof shape/slope?`,
    options: [
      {
        label: 'Flat',
        value: 'FLAT',
        image: GraphicHomeDetailFlatImageUrl,
        alt: 'Flat',
        helpText:
          'Roofs considered to be flat have a minor slope which is not noticeable to most people. Because of its slight slope, conventional shingles cannot be used so they are typically covered with a built-up (hot mopped) rubber or vinyl membrane or with sprayed-on foam material.',
      },
      {
        label: 'Low-Moderate',
        value: 'LOWMOD',
        image: GraphicHomeDetailGableImageUrl,
        alt: 'Low-Moderate',
        helpText:
          'Roof style consisting of two sides that slope in opposite directions down from the peak or ridge. These sides may or may not have the same pitch. The roof ends form an inverted "V" and are filled in with triangular-shaped gable end walls.',
      },
      {
        label: 'Steep',
        value: 'STEEP',
        image: GraphicHomeDetailHipImageUrl,
        alt: 'Steep',
        helpText:
          'A roof which slopes upward from all four sides of a building, requiring a hip rafter at each corner. It typically resembles a "pyramid" or elongated pyramid.',
      },
    ],
  },
  'property.singleOrMultiFamily': {
    title: 'What type of home is it?',
    options: [
      {
        label: 'Single Family Dwelling',
        value: 'SingleFamily',
        image: GraphicHomeDetailPrimaryImageUrl,
        helpText: 'Single Family Dwelling',
        alt: 'SingleFamily',
      },
      {
        label: 'Duplex',
        value: 'Duplex',
        image: GraphicHomeDetailPrimaryImageUrl,
        helpText: 'Duplex',
        alt: 'Duplex',
      },
      {
        label: 'Single Family Unit within a Townhouse/Rowhouse',
        value: 'TownRow',
        image: GraphicHomeDetailPrimaryImageUrl,
        helpText: 'Single Family Unit within a Townhouse / Rowhouse',
        alt: 'TownRow',
      },
      {
        label: '3 or More Family Dwelling',
        value: '3OrMoreFam',
        image: GraphicHomeDetailPrimaryImageUrl,
        helpText: '3 or More Family Dwelling',
        alt: '3OrMoreFam',
      },
      {
        label: 'Apartment Complex',
        value: 'Apt',
        image: GraphicHomeDetailPrimaryImageUrl,
        helpText: 'Apartment Complex',
        alt: 'Apt',
      },
      {
        label: 'Condominium/Co-op',
        value: 'CondoCoop',
        image: GraphicHomeDetailPrimaryImageUrl,
        helpText: 'Condominium/Co-op',
        alt: 'CondoCoop',
      },
      {
        label: 'Manufactured Home',
        value: 'ManufacturedHome',
        image: GraphicHomeDetailPrimaryImageUrl,
        helpText: 'Manufactured Home',
        alt: 'ManufacturedHome',
      },
    ],
  },
  'property.constructionType': {
    title: `What type of construction is the home?`,
    options: [
      {
        sortingKey: 3,
        label: 'Frame',
        value: 'FRAME',
        image: GraphicHomeDetailsInterWallTypeStudsOnlyImageUrl,
        alt: 'Frame',
        helpText: 'A Classic wood framed home.',
      },
      {
        sortingKey: 2,
        label: 'Concrete',
        value: 'CONCRETE',
        image: GraphicHomeDetailsInterWallTypeConcreteBlockImageUrl,
        alt: 'Concrete',
        helpText: 'Home is built using concrete.',
      },
      {
        sortingKey: 5,
        label: 'Masonry',
        value: 'MASONRY',
        image: GraphicHomeDetailsWallConstructionBrickOnMasonryImageUrl,
        alt: 'Masonry',
        helpText: 'Structure is built with brick, stone or similar material.',
      },
      {
        sortingKey: 6,
        label: 'Steel',
        value: 'STEEL',
        image: GraphicHomeDetailsWallConstructionSteelSidingImageUrl,
        alt: 'Steel',
        helpText: 'Home is typically constructed of steel.',
      },
      {
        sortingKey: 4,
        label: 'Log',
        value: 'LOG',
        image: GraphicHomeDetailsWallConstructionLogsImageUrl,
        alt: 'Log',
        helpText:
          'Solid logs that are 8" to 12" in diameter, with the log visible on the exterior and interior of the home. The space between each log is caulked to form a weather-tight seal.',
      },
      {
        sortingKey: 1,
        label: 'Adobe Block',
        value: 'ADOBE',
        image: GraphicHomeDetailsInterWallTypeAdobeImageUrl,
        alt: 'Adobe',
        helpText:
          '1 or 2 stories, with adobe block construction and simple floor plans. Popular in the southwestern U.S., include flat roofs.',
      },
      {
        sortingKey: 7,
        label: 'Other',
        value: 'OTHER',
        image: IconUIQuestionImageUrl,
        alt: 'Other',
      },
    ],
  },
  'property.constructionTypeDescription': {
    title: 'Describe the construction.',
  },
  'exteriorWall.<id>.constructionMaterial': {
    title: `What is the home's primary exterior wall finish?`,
    options: [
      {
        label: 'Brick Veneer',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.BRICK_VENEER',
        image: GraphicHomeDetailsWallConstructionBrickVeneerImageUrl,
        alt: 'Brick Veneer',
        sortingKey: 1,
      },
      {
        label: 'Concrete Block',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.CONCRETE_BLOCK',
        image: GraphicHomeDetailsWallConstructionConcreteBlockImageUrl,
        alt: 'Concrete Block',
        helpText:
          'A home constructed of concrete block or masonry. The exterior block is usually painted and does not have any other exterior material covering the block. These types of homes are rarely constructed.',
        sortingKey: 2,
      },
      {
        label: 'Earth Home',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.EARTH',
        alt: 'Earth Home',
        image: GraphicHomeDetailsWallConstructionEarthHomeImageUrl,
        sortingKey: 3,
      },
      {
        label: 'EIFS',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STUCCO_ON_FRAME_EIFS',
        image: GraphicHomeDetailsWallConstructionEIFSSyntheticStuccoImageUrl,
        alt: 'EIFS',
        helpText:
          'Exterior Insulation and Finish Systems. Lightweight and synthetic. Has inner (foam insulation), middle (base coat) and exterior layers (finish coat).',
        sortingKey: 4,
      },
      {
        label: 'Siding - Vinyl',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.VINYL_SIDING',
        image: GraphicHomeDetailsWallConstructionVinylSidingImageUrl,
        alt: 'Siding - Vinyl',
        helpText:
          'Strong, impact resistant planks. Installed horizontally and attached directly to the exterior framing.',
        sortingKey: 9,
      },
      {
        label: 'Siding - Aluminum / Steel',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.ALUMINUM_STEEL_SIDING',
        // TODO: Is Steel siding same as Aluminium Steel/ Siding
        image: GraphicHomeDetailsWallConstructionSteelSidingImageUrl,
        alt: 'Siding - Aluminum / Steel',
        helpText:
          'Steel siding is resistant to rot, insects, and fire damage. It is stronger, heavier, and more resistant to denting than aluminum. Susceptible to rusting.',
        sortingKey: 5,
      },
      {
        label: 'Siding - Cement Fiber',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.CEMENT_FIBER_SIDING',
        image: GraphicHomeDetailsWallConstructionHardyPlankCementFiberImageUrl,
        alt: 'Siding - Cement Fiber',
        helpText:
          'Cement-like product installed horizontally and attached directly to the exterior framing.',
        sortingKey: 6,
      },
      {
        label: 'Siding - Hardboard / Masonite',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.HARDBOARD_SIDING',
        image: GraphicHomeDetailsWallConstructionHardboardSidingImageUrl,
        alt: 'Siding - Hardboard / Masonite',
        helpText:
          'Cement-like product installed horizontally and attached directly to the exterior framing.',
        sortingKey: 7,
      },
      {
        label: 'Siding - Log',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.LOG_SIDING',
        image: GraphicHomeDetailsWallConstructionLogsImageUrl,
        alt: 'Siding - Log',
        helpText:
          'Rounded wood planks that attach directly to the exterior framing structure, and give the look of a log cabin. Usually made from pine.',
        sortingKey: 8,
      },
      {
        label: 'Siding - Wood',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.WOOD_SIDING',
        image: GraphicHomeDetailsWallConstructionWoodSidingImageUrl,
        alt: 'Siding - Wood',
        helpText:
          'Types include board and batten, cypress/reclaimed plywood, cedar, pine and redwood.',
        sortingKey: 10,
      },
      {
        label: 'Stone Veneer',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STONE_VENEER',
        alt: 'Stone Veneer',
        image: GraphicHomeDetailsWallConstructionStoneVeneerImageUrl,
        sortingKey: 14,
      },
      {
        label: 'Stucco',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STUCCO_ON_FRAME',
        image: GraphicHomeDetailsWallConstructionStuccoOnFrameImageUrl,
        alt: 'Stucco',
        helpText:
          'Made by mixing sand and lime with water and other ingredients - most often cement.',
        sortingKey: 15,
      },
      {
        label: 'Solid Brick',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.BRICK_SOLID',
        alt: 'Solid Brick',
        image: GraphicHomeDetailsWallConstructionSolidBrickImageUrl,
        sortingKey: 11,
      },
      {
        label: 'Solid Stone',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STONE_SOLID',
        alt: 'Solid Stone',
        image: GraphicHomeDetailsWallConstructionSolidStoneImageUrl,
        sortingKey: 13,
      },
      {
        label: 'Stucco on Masonry',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STUCCO_ON_MASONRY',
        image: GraphicHomeDetailsWallConstructionStuccoOnMasonryImageUrl,
        alt: 'Stucco on Masonry',
        helpText:
          'Made by mixing sand and lime with water and other ingredients - most often cement. Mixture is applied to specially prepared masonry surfaces.',
        sortingKey: 16,
      },
      {
        label: 'Solid Logs',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.LOG_SOLID',
        alt: 'Solid Logs',
        image: GraphicHomeDetailsWallConstructionSolidLogsImageUrl,
        // No image or helptext
        sortingKey: 12,
      },
      {
        label: 'Other',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.OTHER',
        alt: 'Other',
        image: IconUIQuestionImageUrl,
        sortingKey: 17,
      },
    ],
  },
  'exteriorWall.<id>.constructionMaterialPercentage': {
    title: 'Exterior Wall Construction Material Percentage',
    options: [
      {
        label: '25%',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL_PERCENTAGE.TWENTY_FIVE_PERCENT',
      },
      {
        label: '50%',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL_PERCENTAGE.FIFTY_PERCENT',
      },
      {
        label: '75%',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL_PERCENTAGE.SEVENTY_FIVE_PERCENT',
      },
      {
        label: '100%',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL_PERCENTAGE.ONE_HUNDRED_PERCENT',
      },
    ],
  },
  'exteriorWall.<id>.description': {
    title: 'Describe the exterior wall finish.',
  },
  'property.washingMachineLocation': {
    title: 'Where is the clothes washing machine located?',
    options: [
      { label: 'Basement (No Levels Below)', value: 'BASEMENT', sortingKey: 3 },
      { label: 'Upper Level', value: 'UPPER_LEVEL', sortingKey: 1 },
      { label: 'Main Level', value: 'MAIN_LEVEL', sortingKey: 2 },
      { label: 'No Washing Machine in Dwelling', value: 'NA', sortingKey: 4 },
    ],
  },
  'property.wildfireDefenseServiceInd': {
    title: 'Does [pniFirstName] plan to sign up for the Wildfire Defense Service?',
  },
  'property.renovation.wiringReplacedYear': {
    title: 'What Year was your Wiring Installed/Replaced?',
  },
  'property.noOfOccupants': {
    title: 'How many people live in the home?',
  },
  'property.yearOccupied': {
    title: 'When did [pniFirstName] purchase this home?',
  },
  'person.<id>.additionalInformation.occupantType': {
    title: 'What type of occupant is [pniFirstName]?',
  },
  'person.<id>.additionalInformation.isOwnedByImmediateFamily': {
    title: 'Is the residence owned by immediate family member, like a parent or child?',
  },
  'property.garageCarport': {
    title: 'Does the home have any attached garages or carports?',
  },
  'garage.<id>.size': {
    title: 'How many parking spots does the home have?',
  },
  'garage.<id>.type': {
    title: 'What type of garage or carport does the home have?',
    options: [
      {
        label: 'Carport - Attached',
        value: 'GARAGE.TYPE.CARPORT',
        sortingKey: 1,
      },
      {
        label: 'Garage - Attached',
        value: 'GARAGE.TYPE.ATTACHED_NO_LIVING_SPACE_ABOVE',
        sortingKey: 2,
      },
      {
        label: 'Garage - Basement',
        value: 'GARAGE.TYPE.BASEMENT',
        sortingKey: 3,
      },
      {
        label: 'Garage - Built-in',
        value: 'GARAGE.TYPE.BUILT_IN_LIVING_SPACE_ABOVE',
        sortingKey: 4,
      },
    ],
  },
  'property.livingArea': {
    title: 'How many square feet are above ground?',
  },
  // Add delta questions below
  'delta.property.noOfAmps': {
    title: 'How many amps does the electrical system have?',
  },
  'delta.property.solarPanels': {
    title: 'Does the home have solar panels?',
  },
  // Add coverages questions below ----------------->
  'amfam-adv.renters.coverages.lossAssessment.limit': {
    options: [
      {
        label: '$2,000',
        value: '2000',
      },
      {
        label: '$10,000',
        value: '10000',
      },
      {
        label: '$50,000',
        value: '50000',
      },
    ],
  },
  'amfam-adv.renters.coverages.moldCoverage.sectionILimit': {
    options: [
      {
        label: '$5,000',
        value: '5000',
      },
      {
        label: '$10,000',
        value: '10000',
      },
    ],
  },
  'amfam-adv.renters.coverages.pollutionCleanup.limit': {
    options: [
      {
        label: '$5,000',
        value: '5000',
      },
    ],
  },
  'amfam-adv.renters.coverages.outsideWaterSource.limit': {
    options: [
      {
        label: '$25,000',
        value: '25000',
      },
    ],
  },
  'amfam-adv.renters.coverages.equipmentBreakdown.limit': {
    title: 'Limit',
    options: [
      {
        label: '$100,000',
        value: '100000',
      },
      {
        label: 'No Coverage',
        value: 'NoCoverage',
      },
    ],
  },
  'amfam-adv.renters.coverages.equipmentBreakdown.deductible': {
    title: 'Deductible',
    options: [
      {
        label: '$500',
        value: '500',
      },
      {
        label: 'No Coverage',
        value: 'NoCoverage',
      },
    ],
  },
  'amfam-adv.renters.coverages.animalLiability.limit': {
    options: [
      {
        label: '$25,000',
        value: '25000',
      },
    ],
  },
  'amfam-adv.renters.coverages.fireService.limit': {
    options: [
      {
        label: '$500',
        value: '500',
      },
      {
        label: '$1,000',
        value: '1000',
      },
      {
        label: '$1,500',
        value: '1500',
      },
      {
        label: '$2,000',
        value: '2000',
      },
    ],
  },
  'amfam-adv.renters.coverages.replacementCost.selected': {
    options: [
      {
        label: 'Included',
        value: 'true',
      },
    ],
  },
  'amfam-adv.renters.coverages.identityFraudExpense.limit': {
    title: 'Limit',
    options: [
      {
        label: '$25,000',
        value: '25000',
      },
      {
        label: 'No Coverage',
        value: 'NoCoverage',
      },
    ],
  },
  'amfam-adv.renters.coverages.identityFraudExpense.deductible': {
    title: 'Deductible',
    options: [
      {
        label: '$250',
        value: '250',
      },
      {
        label: 'No Coverage',
        value: 'NoCoverage',
      },
    ],
  },
  'amfam-adv.renters.coverages.jewelry.limit': {
    title: 'Per Item / Total Limit',
    options: [
      {
        label: '$2,000 / $2,500',
        value: '2000/2500',
      },
      {
        label: '$2,000 / $5,000',
        value: '2000/5000',
      },
    ],
  },
  'delta.property.wiringType': {
    title: 'What type of electrical system does the home have?',
    options: [
      {
        label: 'Conduit',
        value: 'CONDUIT',
      },
      {
        label: 'Plastic Coated (Romex)',
        value: 'PLASTIC',
      },
      {
        label: 'BX/Armored Cable',
        value: 'BXCABLE',
      },
      {
        label: 'Knob & Tube',
        value: 'KNOBTUBE',
      },
      {
        label: 'Cloth Wrapped',
        value: 'CLOTH',
      },
      {
        label: 'Aluminum',
        value: 'ALUMINUM',
      },
      {
        label: 'Other',
        value: 'OTHER',
      },
      {
        label: 'None',
        value: 'NONE',
      },
    ],
  },
  'delta.property.otherWiringTypeDescription': {
    title: 'Electrical System Other Description',
  },
  'amfam-adv.renters.coverages.waterBackup.sewer': {
    title: 'Sewer Back-Up',
    options: [
      {
        label: 'No Coverage',
        value: 'false',
      },
      {
        label: 'Coverage Selected',
        value: 'true',
      },
    ],
  },
  'amfam-adv.renters.coverages.waterBackup.septic': {
    title: 'Septic Back-Up',
    options: [
      {
        label: 'No Coverage',
        value: 'false',
      },
      {
        label: 'Coverage Selected',
        value: 'true',
      },
    ],
  },
  'amfam-adv.renters.coverages.waterBackup.sump': {
    title: 'Sump Overflow',
    options: [
      {
        label: 'No Coverage',
        value: 'false',
      },
      {
        label: 'Coverage Selected',
        value: 'true',
      },
    ],
  },
};

export default propertyQuestionsUIMetadata;
