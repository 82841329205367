import { useCallback } from 'react';

import { trackClick, trackEntryComplete, trackEntryStart } from '@ecp/utils/analytics/tracking';
import { noop } from '@ecp/utils/common';

import type { DatePickerProps } from '@ecp/components';
import { DatePicker as DatePickerBase } from '@ecp/components';
import { useFieldRef } from '@ecp/features/sales/form';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

interface Props extends DatePickerProps {
  analyticsElement?: string;
  trackingLabel?: string;
  trackingName?: string;
  actionOnChange(value: AnswerValue): void;
  actionOnComplete(value: AnswerValue): void;
}

export const DatePicker: React.FC<Props> = (props) => {
  const {
    analyticsElement,
    hidePicker,
    name,
    trackingLabel,
    trackingName,
    actionOnChange,
    actionOnComplete,
    onFocus = noop,
    ...rest
  } = props;
  const inputRef = useFieldRef(name);

  const handleFocus: NonNullable<DatePickerProps['onFocus']> = useCallback(
    (event) => {
      if (analyticsElement) {
        trackSapiAnalyticsEvent({
          element: analyticsElement,
          event: 'click',
          eventDetail: 'true',
        });
      }
      if (trackingName && trackingLabel && hidePicker) {
        trackEntryStart({ action: trackingName, label: trackingLabel });
      }
      onFocus(event);
    },
    [trackingName, trackingLabel, hidePicker, onFocus, analyticsElement],
  );

  const handleChange: NonNullable<DatePickerProps['actionOnChange']> = useCallback(
    (value) => {
      actionOnChange(value);
      if (trackingName && !hidePicker) {
        trackClick({ action: trackingName, label: value });
      }
    },
    [actionOnChange, hidePicker, trackingName],
  );

  const handleComplete: NonNullable<DatePickerProps['actionOnComplete']> = useCallback(
    (value) => {
      actionOnComplete(value);
      if (trackingName && trackingLabel && hidePicker) {
        trackEntryComplete({ action: trackingName, label: trackingLabel });
      }
    },
    [actionOnComplete, trackingName, trackingLabel, hidePicker],
  );

  const handleOpen: NonNullable<DatePickerProps['onOpen']> = useCallback(() => {
    if (analyticsElement) {
      trackSapiAnalyticsEvent({
        element: analyticsElement,
        event: 'click',
        eventDetail: 'true',
      });
    }
  }, [analyticsElement]);

  return (
    <DatePickerBase
      actionOnChange={handleChange}
      actionOnComplete={handleComplete}
      fullWidth
      hidePicker={hidePicker}
      inputRef={inputRef}
      name={name}
      onFocus={handleFocus}
      onOpen={handleOpen}
      {...rest}
    />
  );
};
