import { Divider, Grid, Typography } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { getVehicleDescription } from '@ecp/features/sales/quotes/auto';
import { Button } from '@ecp/features/sales/shared/components';
import { getVehicles } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconUIDelete, IconUIEdit } from '@ecp/themes/base';

import { useStyles } from './AutoTPIPageForm.styles';

export const AutoTPIPageForm: React.FC = () => {
  const { classes } = useStyles();

  const vehicles = useSelector(getVehicles);
  // TODO this need updates when shared component is merged
  // const handleAddTPI = useCallback(
  //   async (vehicleRef: string) => {
  //     const newTPIRef = addTPI(vehicleRef);
  //     const { tpiRef } = newTPIRef;
  //     await newTPIRef.updateTPIVehicleAddressRef;
  //     await dispatch(
  //       navigateToAddTPI({ ref: tpiRef, replace: false, referencePage: 'AddVehicleTPI' }),
  //     );
  //   },
  //   [addTPI, dispatch],
  // );

  return (
    <GridItem>
      {vehicles.map((vehicle, index) => (
        <GridItem key={index} className={classes.card}>
          <Grid container key={vehicle.ref}>
            <GridItem xs={12} md={6}>
              <Typography variant='body3'>{getVehicleDescription(vehicle)}</Typography>
              {vehicle.vin && <p className={classes.contentSubTitle}>{`VIN: ${vehicle.vin}`}</p>}
            </GridItem>
            <GridItem xs={12} md={6} className={classes.columnRight}>
              <Button
                className={classes.button}
                variant='outlinePrimary'
                // onClick={() => handleAddTPI(vehicle.ref)}
                trackingName='sni_details_continue'
                trackingLabel={GoogleAnalyticsLabels.CONTINUE}
              >
                ADD INTERESTED PARTY
              </Button>
            </GridItem>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container>
            <GridItem xs={12} md={6}>
              <p className={classes.title}>Tennesse Credit Union</p>
              <p>Loan no: 032145699789874645</p>
            </GridItem>
            <Grid item xs={12} md={6} className={classes.columnRight}>
              <GridItem>
                <Button
                  variant='iconTextMedium'
                  icon={<IconUIEdit />}
                  className={classes.actionButton}
                >
                  Edit
                </Button>
              </GridItem>
              <GridItem>
                <Button
                  variant='iconTextMedium'
                  icon={<IconUIDelete />}
                  className={classes.actionButton}
                >
                  Remove
                </Button>
              </GridItem>
            </Grid>
          </Grid>
        </GridItem>
      ))}
    </GridItem>
  );
};
