import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';

import { getSignatureMethodsByPerson } from '../../../state/acknowledgements';
import { InPersonClick2SignPerson } from './InPersonClick2SignPerson';

export interface Props extends QuestionProps {
  selectedProduct: Product;
}

export const InPersonClick2Sign: React.FC<Props> = (props) => {
  const { selectedProduct } = props;

  const signaturePersons = useSelector((state) =>
    getSignatureMethodsByPerson(state, selectedProduct, 'IN_PERSON_CLICK_SIGN'),
  );

  return (
    <>
      {Object.entries(signaturePersons).map(([personRef]) => (
        <InPersonClick2SignPerson personRef={personRef} />
      ))}
    </>
  );
};
