import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'InPersonClick2SignPerson' })((theme) => ({
  container: {
    alignItems: 'center',
  },
  items: {
    display: 'inline-block',
    flexBasis: 'auto',
    marginRight: '40px',
  },
  button: {
    paddingLeft: '0 !important',
  },
  namedInsured: {
    display: 'inline-block',
  },
}));
