import { emptyArray } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { ConfigType } from '@ecp/features/sales/shared/constants';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import type { LineOfBusiness, Product } from '@ecp/features/shared/product';
import { hasPropertyProduct, productMatchesLOB } from '@ecp/features/shared/product';

import {
  getLastConfigVisitedPage,
  getLineOfBusiness,
  getLineOfBusinessOrder,
  getPartnerExperienceId,
} from '../inquiry/selectors';
import { getAggregatePathTrackingStatus, getNavTracking } from '../nav';
import type { NavTracking } from '../nav/types';
import { getProductsForLOB } from '../offers';
import { getPageFlowPaths, getPageFlowReferencePartialPaths } from './configUtil';
import type { PageFlow } from './types';

// Sorts page flow based on product order, since product order is based on user selection
const sortByProductOrder = (state: RootStore, flows: PageFlow[]): PageFlow[] => {
  const productOrder: string[] = getLineOfBusinessOrder(state).reduce((result, lob) => {
    const id = flows.find((flow) => productMatchesLOB(lob, flow.id as Product))?.id;
    if (id) result.push(id);

    return result;
  }, [] as string[]);

  const sortedPageFlows = flows
    .filter((p) => p.value?.pageFlows?.length)
    .sort((a, b) => productOrder.indexOf(a.id) - productOrder.indexOf(b.id));

  return sortedPageFlows;
};
export const getConfig = (state: RootStore): RootStore['config'] => state.config;

export const getPageFlowIntro = (state: RootStore): PageFlow => state.config.intro;

export const getPageFlowProducts = (state: RootStore): PageFlow[] => {
  return sortByProductOrder(state, state.config.products);
};

export const getPageFlowDelta = (state: RootStore): PageFlow[] => {
  return sortByProductOrder(state, state.config.deltas);
};

export const getProductOrder = (state: RootStore): string[] => state.config.productOrder;

export const getProductFlowForLOB = (
  state: RootStore,
): { lob: LineOfBusiness; pageFlow: PageFlow }[] => {
  const lobs = getLineOfBusinessOrder(state);
  const productFlow = getPageFlowProducts(state);

  return lobs.length === productFlow.length
    ? lobs.map((lob, index) => {
        return {
          lob: lob,
          pageFlow: productFlow[index],
        };
      })
    : (emptyArray as unknown as { lob: LineOfBusiness; pageFlow: PageFlow }[]);
};

export const getAllConfigPagePaths = (state: RootStore): string[] => {
  const allPageFlows = getAllPageFlows(state);
  const paths: string[] = [];
  allPageFlows.forEach((pageFlow) => {
    Object.keys(pageFlow.value.byPageId).forEach((key) => {
      paths.push(pageFlow.value.byPageId[key].path);
    });
  });

  return paths;
};

const getValidPageFlows = (pageFlows: PageFlow[]): PageFlow[] => {
  return pageFlows.filter((pageFlow) => Object.keys(pageFlow.value).length > 0);
};

export const getAllPageFlows = (state: RootStore): PageFlow[] => {
  const introProductPageFlows = [state.config.intro, ...getPageFlowProducts(state)];
  const summaryDeltaAndPurchaseFlows = [
    state.config.initialSummary,
    ...getPageFlowDelta(state),
    state.config.finalSummary,
    state.config.purchase,
  ];

  const expId = getPartnerExperienceId(state);
  const isASP = expId === '2860';

  const isTpiEnabled = flagValues[FeatureFlags.THIRD_PARTY_INTEREST_FLOW];

  if (isSniPageEnabled(state) && state.config.secondaryNamedInsured) {
    introProductPageFlows.push(state.config.secondaryNamedInsured);
  }

  if (isTpiEnabled && state.config.thirdPartyInterest && !isASP) {
    introProductPageFlows.push(state.config.thirdPartyInterest);
  }

  if (
    flagValues[FeatureFlags.HOME_PREFILL_SUMMARY_PAGE_ABC_TEST] === 'VARIANT_A' &&
    state.config.homePrefillSummary
  ) {
    introProductPageFlows.push(state.config.homePrefillSummary);
  }
  if (isTpiEnabled && state.config.thirdPartyInterest && isASP) {
    summaryDeltaAndPurchaseFlows.splice(
      summaryDeltaAndPurchaseFlows.findIndex(
        (ele) => ele.id === ConfigType.FINAL_SUMMARY_PAGE_FLOW,
      ),
      0,
      state.config.thirdPartyInterest,
    );
  }

  if (flagValues[FeatureFlags.THIRD_PARTY_REPORTS_FLOW] && state.config.thirdPartyReports) {
    summaryDeltaAndPurchaseFlows.splice(
      summaryDeltaAndPurchaseFlows.findIndex(
        (ele) => ele.id === ConfigType.FINAL_SUMMARY_PAGE_FLOW,
      ),
      0,
      state.config.thirdPartyReports,
    );
  }

  return getValidPageFlows([...introProductPageFlows, ...summaryDeltaAndPurchaseFlows]);
};

export const getSummaryDeltaAndPurchaseFlows = (state: RootStore): PageFlow[] => {
  let summaryDeltaAndPurchaseFlows = [
    state.config.initialSummary,
    ...getPageFlowDelta(state),
    state.config.finalSummary,
    state.config.purchase,
  ];

  const expId = getPartnerExperienceId(state);
  const isASP = expId === '2860';

  const isTpiEnabled = flagValues[FeatureFlags.THIRD_PARTY_INTEREST_FLOW];

  if (isTpiEnabled && state.config.thirdPartyInterest && !isASP) {
    summaryDeltaAndPurchaseFlows = [state.config.thirdPartyInterest].concat(
      summaryDeltaAndPurchaseFlows,
    );
  }

  if (isSniPageEnabled(state) && state.config.secondaryNamedInsured) {
    summaryDeltaAndPurchaseFlows = [state.config.secondaryNamedInsured].concat(
      summaryDeltaAndPurchaseFlows,
    );
  }

  if (
    flagValues[FeatureFlags.HOME_PREFILL_SUMMARY_PAGE_ABC_TEST] === 'VARIANT_A' &&
    state.config.homePrefillSummary
  ) {
    summaryDeltaAndPurchaseFlows.splice(
      summaryDeltaAndPurchaseFlows.findIndex(
        (ele) => ele.id === ConfigType.INITIAL_SUMMARY_PAGE_FLOW,
      ),
      0,
      state.config.homePrefillSummary,
    );
  }

  if (isTpiEnabled && state.config.thirdPartyInterest && isASP) {
    summaryDeltaAndPurchaseFlows.splice(
      summaryDeltaAndPurchaseFlows.findIndex(
        (ele) => ele.id === ConfigType.FINAL_SUMMARY_PAGE_FLOW,
      ),
      0,
      state.config.thirdPartyInterest,
    );
  }

  if (flagValues[FeatureFlags.THIRD_PARTY_REPORTS_FLOW] && state.config.thirdPartyReports) {
    summaryDeltaAndPurchaseFlows.splice(
      summaryDeltaAndPurchaseFlows.findIndex(
        (ele) => ele.id === ConfigType.FINAL_SUMMARY_PAGE_FLOW,
      ),
      0,
      state.config.thirdPartyReports,
    );
  }

  return summaryDeltaAndPurchaseFlows.filter((pageFlow) => Object.keys(pageFlow.value).length > 0);
};

export const getDeltaAndPurchaseFlows = (state: RootStore): PageFlow[] => {
  let deltaAndPurchaseFlows = [
    ...getPageFlowDelta(state),
    state.config.finalSummary,
    state.config.purchase,
  ];

  if (isSniPageEnabled(state) && state.config.secondaryNamedInsured) {
    deltaAndPurchaseFlows = [state.config.secondaryNamedInsured].concat(deltaAndPurchaseFlows);
  }

  return deltaAndPurchaseFlows.filter((pageFlow) => Object.keys(pageFlow.value).length > 0);
};

export const getActiveRootStepNumber = (state: RootStore): number => {
  const lastConfigVisitedPage = getLastConfigVisitedPage(state);
  const allPageFlows = getAllPageFlows(state);

  const currentPageFlow = allPageFlows.find((pageFlow) =>
    Object.keys(pageFlow.value.byPageId)
      .map((key) => pageFlow.value.byPageId[key].path)
      .includes(lastConfigVisitedPage),
  );

  if (currentPageFlow) return allPageFlows.indexOf(currentPageFlow);

  return -1;
};

export const getAllProductPaths = (state: RootStore, excludePaths?: string[]): Array<string> => {
  const introPageFlow = getPageFlowIntro(state);
  const productPageFlows = getPageFlowProducts(state);
  const introPageFlowPaths = getPageFlowPaths(introPageFlow);
  const productPageFlowPaths = productPageFlows.flatMap((productPageFlow) =>
    getPageFlowPaths(productPageFlow),
  );

  return excludePaths
    ? [...introPageFlowPaths, ...productPageFlowPaths].filter((p) => !excludePaths.includes(p))
    : [...introPageFlowPaths, ...productPageFlowPaths];
};

export const getProductTrackingStatus = (
  state: RootStore,
  excludePaths?: string[],
): NavTracking => {
  const navTracking = getNavTracking(state);
  const allProductPaths = getAllProductPaths(state, excludePaths);

  const productPageFlowReferencePaths = getPageFlowProducts(state).flatMap((productPageFlow) =>
    getPageFlowReferencePartialPaths(productPageFlow),
  );

  return getAggregatePathTrackingStatus(
    navTracking,
    allProductPaths,
    productPageFlowReferencePaths,
  );
};

export const isSniPageEnabled = (state: RootStore): boolean => {
  const lineOfBusiness = getLineOfBusiness(state);
  const selectedProducts = lineOfBusiness ? getProductsForLOB(state) : [];

  const sniQuestionsEnabled = flagValues[FeatureFlags.SNI_DRIVER_QUESTIONS];
  const sniPageFeatureEnabled = flagValues[FeatureFlags.SECONDARY_NAMED_INSURED_PAGE];
  const sniPageEnabled =
    // SNIPage enabled, Auto SNI is on Additional Driver page, but there is a property product
    (sniPageFeatureEnabled && sniQuestionsEnabled && hasPropertyProduct(selectedProducts)) ||
    // SNIPage enabled, Auto SNI isn't rendered on Additional Driver page
    (sniPageFeatureEnabled && !sniQuestionsEnabled);

  return sniPageEnabled;
};
