import { makeStyles } from '@ecp/themes/base';

/**
 * Pass props.backgroundColor if backgroundColor is non-default (non-white).
 * Setting backgroundColor on the leftWrapper element and having such composition here will
 * put the delimiting dots between left and right elements in such way, that
 * they start right after the last char on the last line of the left element (when it's multiline)
 * and end right before the first char on the first line of the right element
 */
export const useStyles = makeStyles({ name: 'SingleLineDetail' })((theme) => ({
  root: {
    ...theme.typography.body1,
    display: 'block',
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
  },
  left: {
    alignSelf: 'flex-start',
    zIndex: 1,
    marginRight: 12,
  },
  leftWrapper: {
    backgroundColor: theme.palette.grey[50],
    paddingRight: '0.2em',
  },
  leftWrapperNoBackground: {
    paddingRight: '0.2em',
  },
  right: {
    ...theme.typography.body1Bold,
    marginLeft: 'auto',
    paddingLeft: '0.2em',
    position: 'relative',
    '&:before': {
      content: `'..........................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................'`,
      position: 'absolute',
      left: -999,
      right: '100%',
      overflow: 'hidden',
    },
  },
  // TODO: Revisit the -999 margin on both places
  rightForHideLineContent: {
    ...theme.typography.body1Bold,
    alignSelf: 'flex-end',
    marginLeft: 'auto',
    paddingLeft: '0.2em',
    position: 'relative',
    '&:before': {
      position: 'absolute',
      left: -999,
      right: '100%',
      overflow: 'hidden',
    },
  },
  rightDiscount: {
    color: theme.palette.success.complete,
  },
  rightRegular: {},
  recalculatingText: {
    color: theme.palette.grey[800],
  },
}));
