import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { getSapiTarget } from '../../api';
import { wrapThunkActionWithErrHandler } from '../../util/wrapThunkActionWithErrHandler';
import { setInquiryApiGetSuccess } from '../actions';
import { postProofDraft } from '../api';
import { getDalSessionId } from '../selectors';
import { ProofDraftSection } from '../types';

export const submitProofDraft = wrapThunkActionWithErrHandler<{
  policyTypes?: string[];
  section?: string;
}>(
  ({ policyTypes, section }) =>
    async (dispatch, getState) => {
      const state = getState();
      const dalSessionId = getDalSessionId(state);
      const sapiTarget = getSapiTarget(state);
      const shouldSubmitProofDraft = flagValues[FeatureFlags.PROOF_DRAFT_ENABLED];
      // TODO: Remove this variation once SAPI is ready with new type in all env's
      const useNewSectionType = flagValues[FeatureFlags.PROOF_DRAFT_USING_NEW_SECTION_TYPE];
      const revisedSection = section
        ? section
        : useNewSectionType
        ? ProofDraftSection.PRE_QUOTE
        : 'pre-quote';

      if (!dalSessionId || sapiTarget !== 'v4' || !shouldSubmitProofDraft) {
        return;
      }

      const response = await postProofDraft({ dalSessionId, section: revisedSection, policyTypes });
      dispatch(setInquiryApiGetSuccess(response.payload));
    },
  'submitProofDraft',
);
