import { FeatureFlags, flagValues } from '@ecp/utils/flags';

/**
 * Calculates the maximum number of days in advance a policy start date can be set.
 * The limit varies based on the state and whether the product is auto.
 *
 * @param {Object} params - The parameters for the calculation.
 * @param {string} params.stateCode - The state code (e.g., 'CA', 'WA') for which the policy is being set.
 * @param {boolean} params.isAuto - Indicates if the product is auto.
 * @returns {number} The maximum number of days in advance the policy start date can be set.
 *                   Returns a fixed default for non-auto products, and varies for auto product based on state.
 */
export const calculateMaxPolicyStartDaysLimit = ({
  isAutoProduct,
}: {
  isAutoProduct: boolean;
}): number => {
  // We can assume the flag will be set to true only if the state matches
  if (isAutoProduct && flagValues[FeatureFlags.AUTO_QUOTE_WINDOW_TO_30DAYS]) return 29;

  return DEFAULT_MAX_POLICY_START_DAYS_LIMIT;
};

export const DEFAULT_MAX_POLICY_START_DAYS_LIMIT = 60;
